import { API } from "../constants/api";
import {
  axiosPostWithToken,
} from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { getDateFilter } from "@flexibees/core";

export const GET_FINAL = "GET_FINAL";
export const FINAL_LOADING = "FINAL_LOADING";
export const SET_FINAL_ITEMS_PER_PAGE = "SET_FINAL_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_FINAL_PAGE = "CHANGE_CURRENT_FINAL_PAGE";
export const SEARCH_FINAL = "SEARCH_FINAL";
export const FILTER_FINAL_FUNCTIONS = "FILTER_FINAL_FUNCTIONS";
export const FILTER_FINAL_ROLES = "FILTER_FINAL_ROLES";
export const PULL_DATA_GET_FINAL = "PULL_DATA_GET_FINAL";
export const FILTER_FINAL_RECRUITER = "FILTER_FINAL_RECRUITER";
export const FILTER_FINAL_DATE = "FILTER_FINAL_DATE";
export const SET_FINAL_PRICING_HISTORY = "SET_FINAL_PRICING_HISTORY";
export const FILTER_FINAL_BDMANAGER = "FILTER_FINAL_BDMANAGER";
export const FILTER_FINAL_RECRUITMENT_DAYS = "FILTER_FINAL_RECRUITMENT_DAYS";
export const FILTER_FINAL_NO_OF_POSITIONS = "FILTER_FINAL_NO_OF_POSITIONS";
export const FILTER_FINAL_FLEXIBEES_SELECTED =
  "FILTER_FINAL_FLEXIBEES_SELECTED";
export const FILTER_FINAL_CLIENT_SELECTED = "FILTER_FINAL_CLIENT_SELECTED";

export const getFinal = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().final.finalResultsPerPage;
    const page = getstate().final.finalCurrentPage;
    const seachText = getstate().final.finalSearchText;
    const functions = getstate().final.finalAppliedFunctionFilter;
    const roles = getstate().final.finalAppliedRolesFilter;
    const recruiters = getstate().final.finalAppliedRecruiterFilter;
    const bdManagers = getstate().final.finalAppliedBDManagerFilter;
    const date = getDateFilter(getstate().final.finalAppliedDateFilter[0]);
    const no_of_days = getstate().final.finalAppliedRecruitmentDaysFilter;
    const no_of_positions = getstate().final
      .finalAppliedPositionsToBeFilledFilter;
    const flexibees_selected = getstate().final
      .finalAppliedFlexibeesSelectedFilter;
    const client_selected = getstate().final.finalAppliedClientSelectedFilter;

    const body = {
      search_term: seachText,
      filter_data: {
        functions: functions,
        roles: roles,
        recruiters: recruiters,
        start_date: date,
        bd_managers: bdManagers,
        no_of_days: no_of_days,
        no_of_positions: no_of_positions,
        flexibees_selected: flexibees_selected,
        client_selected: client_selected,
      },
    };

    axiosPostWithToken(
      `${API.GET_FINAL}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_FINAL,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch data",
        });
      }
    });
  };
};

export const closeProject = (projectId, body, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(`${API.CLOSE_PROJECT}/${projectId}/`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          refresh();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              res.data.data.message ||
              "Something went wrong. Unable to process request",
          });
        }
      }
    );
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: SET_FINAL_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_FINAL_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: SEARCH_FINAL,
      payload: value,
    });
  };
};

export const filterFunctions = (functions) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_FUNCTIONS,
      payload: functions,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_ROLES,
      payload: roles,
    });
  };
};

export const filterRecruiter = (recruiter) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_RECRUITER,
      payload: recruiter,
    });
  };
};

export const filterBDManager = (bdManager) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_BDMANAGER,
      payload: bdManager,
    });
  };
};

export const filterRecruitmentDays = (days) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_RECRUITMENT_DAYS,
      payload: days,
    });
  };
};

export const filterNoOfPositionsDays = (positions) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_NO_OF_POSITIONS,
      payload: positions,
    });
  };
};

export const filterFlexibeesSelected = (selected) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_FLEXIBEES_SELECTED,
      payload: selected,
    });
  };
};

export const filterClientSelected = (selected) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_CLIENT_SELECTED,
      payload: selected,
    });
  };
};

export const filterStartDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: FINAL_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_DATE,
      payload: date,
    });
  };
};
