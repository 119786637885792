import { API } from "../constants/api";
import { axiosGetWithToken, axiosPostWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { CHANGE_VIEW_PROJECT_TAB } from "./viewProjectTabAction";
import axios from "axios";

export const GET_WORKFLOW_FUNCTIONAL = "GET_WORKFLOW_FUNCTIONAL";
export const WORKFLOW_FUNCTIONAL_LOADING = "WORKFLOW_FUNCTIONAL_LOADING";
export const SET_WORKFLOW_FUNCTIONAL_ITEMS_PER_PAGE =
  "SET_WORKFLOW_FUNCTIONAL_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_WORKFLOW_FUNCTIONAL_PAGE =
  "CHANGE_CURRENT_WORKFLOW_FUNCTIONAL_PAGE";
export const SEARCH_WORKFLOW_FUNCTIONAL = "SEARCH_WORKFLOW_FUNCTIONAL";
export const FILTER_WORKFLOW_FUNCTIONAL_ROLES =
  "FILTER_WORKFLOW_FUNCTIONAL_ROLES";
export const FILTER_WORKFLOW_FUNCTIONAL_SKILLS =
  "FILTER_WORKFLOW_FUNCTIONAL_SKILLS";
export const WORKFLOW_FUNCTIONAL_MIN_REL_EXP =
  "WORKFLOW_FUNCTIONAL_MIN_REL_EXP";
export const WORKFLOW_FUNCTIONAL_MAX_REL_EXP =
  "WORKFLOW_FUNCTIONAL_MAX_REL_EXP";
export const WORKFLOW_FUNCTIONAL_FILTER_REL_EXP =
  "WORKFLOW_FUNCTIONAL_FILTER_REL_EXP";
export const WORKFLOW_FUNCTIONAL_SET_TABLE_SELECTED_ROWS =
  "WORKFLOW_FUNCTIONAL_SET_TABLE_SELECTED_ROWS";
export const RESET_WORKFLOW_FUNCTIONAL = "RESET_WORKFLOW_FUNCTIONAL";
export const FILTER_WORKFLOW_FUNCTIONAL_STATUS =
  "FILTER_WORKFLOW_FUNCTIONAL_STATUS";
export const WORKFLOW_FUNCTIONAL_SET_HASDONE_FILTER =
  "WORKFLOW_FUNCTIONAL_SET_HASDONE_FILTER";
export const WORKFLOW_FUNCTIONAL_SET_MATCHING_FILTER =
  "WORKFLOW_FUNCTIONAL_SET_MATCHING_FILTER";
export const WORKFLOW_FUNCTIONAL_MIN_TOTAL_EXP =
  "WORKFLOW_FUNCTIONAL_MIN_TOTAL_EXP";
export const WORKFLOW_FUNCTIONAL_MAX_TOTAL_EXP =
  "WORKFLOW_FUNCTIONAL_MAX_TOTAL_EXP";
export const WORKFLOW_FUNCTIONAL_FILTER_TOTAL_EXP =
  "WORKFLOW_FUNCTIONAL_FILTER_TOTAL_EXP";
export const SCHEDULE_FLEXIFIT_INTERVIEW = "SCHEDULE_FLEXIFIT_INTERVIEW";
export const GET_FUNCTIONAL_FEEDBACK = "GET_FUNCTIONAL_FEEDBACK";
export const WORKFLOW_FUNCTIONAL_VIEW_FLEXIFIT_FEEDBACK =
  "WORKFLOW_FUNCTIONAL_VIEW_FLEXIFIT_FEEDBACK";
export const FILTER_WORKFLOW_FUNCTIONAL_AVAILABLE_HOURS =
  "FILTER_WORKFLOW_FUNCTIONAL_AVAILABLE_HOURS";
export const VIEW_WORKFLOW_CANDIDATE_TIMELINE =
  "VIEW_WORKFLOW_CANDIDATE_TIMELINE";
export const FILTER_WORKFLOW_FUNCTIONAL_FLEXIBEES_SELECTED =
  "FILTER_WORKFLOW_FUNCTIONAL_FLEXIBEES_SELECTED";

export const FILTER_WORKFLOW_FUNCTIONAL_ACTIVE_PROJECTS =
  "FILTER_WORKFLOW_FUNCTIONAL_ACTIVE_PROJECTS";

export const getCandidateTimeline = (id, toggleModal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    axiosGetWithToken(`${API.GET_CANDIDATE_TIMELINE}/${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          console.log("res.", res.data);
          dispatch({
            type: VIEW_WORKFLOW_CANDIDATE_TIMELINE,
            payload: res.data.data,
          });
          toggleModal();
        } else {
          toggleModal('error');
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || res.data.data.detail || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        toggleModal('error');
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};
export const downloadCandidateTimeline = (id, endDownload) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    var config = {
      headers: { Authorization: token, "Content-Type": "application/pdf" },
      responseType: "blob",
    };

    axios
      .get(`${API.DOWNLOAD_CANDIDATE_TIMELINE}/${id}/`, config)
      .then((response) => {
        endDownload();
        console.log(response.data);
        let url = window.URL.createObjectURL(response.data);
        window.open(url);
      });
  };
};

export const getWorkflowFunctional = (projectId) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize =
      getstate().workflowFunctional.workflowFunctionalResultsPerPage;
    const page = getstate().workflowFunctional.workflowFunctionalCurrentPage;
    const seachText =
      getstate().workflowFunctional.workflowFunctionalSearchText;
    const roles =
      getstate().workflowFunctional.workflowFunctionalAppliedRolesFilter;
    const skills =
      getstate().workflowFunctional.workflowFunctionalAppliedSkillsFilter;
    const total_available_hours =
      getstate().workflowFunctional
        .workflowFunctionalAppliedAvailableHoursFilter;
    const status =
      getstate().workflowFunctional.workflowFunctionalAppliedStatusFilter;
    const minRelExp =
      getstate().workflowFunctional.workflowFunctionalAppliedMinRelExpFilter;
    const maxRelExp =
      getstate().workflowFunctional.workflowFunctionalAppliedMaxRelExpFilter;
    const minTotalExp =
      getstate().workflowFunctional.workflowFunctionalAppliedMinTotalExpFilter;
    const maxTotalExp =
      getstate().workflowFunctional.workflowFunctionalAppliedMaxTotalExpFilter;
    const matching =
      getstate().workflowFunctional.workflowFunctionalAppliedMatchingFilter ===
      "0"
        ? false
        : true;
    const flexibees_selected =
      getstate().workflowFunctional.candidatesListApplaiedFilterFlexiSelected;
    const active_projects =
      getstate().workflowFunctional.candidatesListApplaiedFilterActiveProjects;

    const body = {
      search_term: seachText,
      filter_data: {
        roles: roles,
        skills: skills,
        status: status,
        assignment: matching,
        flexibees_selected: flexibees_selected,
        active_projects: active_projects,
        relevant_experience: {
          min: minRelExp,
          max: maxRelExp,
        },
        years_of_experience: {
          min: minTotalExp,
          max: maxTotalExp,
        },
        total_available_hours: total_available_hours,
      },
    };

    axiosPostWithToken(
      `${API.GET_WORKFLOW_FUNCTIONAL}/${projectId}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        console.log("DATA FUNCTIONAL", res.data);
        dispatch({
          type: GET_WORKFLOW_FUNCTIONAL,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to fetch data.",
        });
      }
    });
  };
};

export const resetTableData = () => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_VIEW_PROJECT_TAB,
      payload: 0,
    });
    dispatch({
      type: RESET_WORKFLOW_FUNCTIONAL,
    });
  };
};

export const skipAssignment = (body, projectId, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(
      `${API.SKIP_ASSIGNMENT}/${projectId}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        refresh("skip");
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to process request.",
        });
      }
    });
  };
};

export const sendAssignment = (body, projectId, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(
      `${API.SEND_ASSIGNMENT}/${projectId}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        refresh("send");
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to process request.",
        });
      }
    });
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: SET_WORKFLOW_FUNCTIONAL_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_WORKFLOW_FUNCTIONAL_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: SEARCH_WORKFLOW_FUNCTIONAL,
      payload: value,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_FUNCTIONAL_ROLES,
      payload: roles,
    });
  };
};

export const filterSkills = (skills) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_FUNCTIONAL_SKILLS,
      payload: skills,
    });
  };
};

export const filterAvilableHours = (hours) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_FUNCTIONAL_AVAILABLE_HOURS,
      payload: hours,
    });
  };
};

export const filterStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_FUNCTIONAL_STATUS,
      payload: status,
    });
  };
};

export const changeMinRelExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_MIN_REL_EXP,
      payload: value,
    });
  };
};

export const changeMaxRelExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_MAX_REL_EXP,
      payload: value,
    });
  };
};

export const clearRelExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_MIN_REL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_MAX_REL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_FILTER_REL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterRelExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_FILTER_REL_EXP,
      payload: true,
    });
    filterData();
  };
};

export const changeMinTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_MIN_TOTAL_EXP,
      payload: value,
    });
  };
};

export const changeMaxTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_MAX_TOTAL_EXP,
      payload: value,
    });
  };
};

export const clearTotalExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_MIN_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_MAX_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_FILTER_TOTAL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterTotalExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_FILTER_TOTAL_EXP,
      payload: true,
    });
    filterData();
  };
};

export const setTableRows = (rows) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_SET_TABLE_SELECTED_ROWS,
      payload: rows,
    });
  };
};

export const setHasDoneFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_SET_HASDONE_FILTER,
      payload: data,
    });
  };
};

export const setMatchingFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FUNCTIONAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FUNCTIONAL_SET_MATCHING_FILTER,
      payload: data,
    });
  };
};

export const scheduleFlexiFitInterview = (functionId, data, successDialog) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(
      `${API.SCHEDULE_FLEXIFIT_INTERVIEW}/${functionId}/`,
      data,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SCHEDULE_FLEXIFIT_INTERVIEW,
          payload: res.data.data,
        });
        successDialog(res.data.data);
      }
    });
  };
};

export const viewFunctionalFeedack = (functionId, openModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosGetWithToken(
      `${API.VIEW_FUNCTIONAL_FEEDBACK}/${functionId}/`,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_FUNCTIONAL_FEEDBACK,
          payload: res.data.data,
        });
        openModal("ViewFunctionalFeedback");
      }
    });
  };
};

export const updateFunctionalFeedback = (functionId, bodyData, openModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    console.log("FUNCTIONA BODY", functionId, bodyData);
    axiosPostWithToken(
      `${API.UPDATE_FUNCTIONAL_FEEDBACK}/${functionId}/`,
      bodyData,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_FUNCTIONAL_FEEDBACK,
          payload: res.data.data,
        });
        openModal();
      }
    });
  };
};

export const getFlexifitFeedback = (id, toggleModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.VIEW_FLEXIFIT_FEEDBACK}/${id}/`, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: WORKFLOW_FUNCTIONAL_VIEW_FLEXIFIT_FEEDBACK,
            payload: res.data.data,
          });
          toggleModal("ViewFlexifitFeedback");
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: "Something went wrong. Unable to fetch data.",
          });
          console.log(`Error: ${res.data.data.message}`);
        }
      }
    );
  };
};

export const setFlexibeesSelectedFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_FUNCTIONAL_FLEXIBEES_SELECTED,
      payload: value,
    });
  };
};

export const setActiveProjectsFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_FUNCTIONAL_ACTIVE_PROJECTS,
      payload: value,
    });
  };
};
