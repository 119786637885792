import {
  GET_WORKFLOW_SHORTLIST,
  WORKFLOW_SHORTLIST_LOADING,
  SET_WORKFLOW_SHORTLIST_ITEMS_PER_PAGE,
  CHANGE_CURRENT_WORKFLOW_SHORTLIST_PAGE,
  SEARCH_WORKFLOW_SHORTLIST,
  FILTER_WORKFLOW_SHORTLIST_ROLES,
  FILTER_WORKFLOW_SHORTLIST_SKILLS,
  WORKFLOW_SHORTLIST_MIN_REL_EXP,
  WORKFLOW_SHORTLIST_MAX_REL_EXP,
  WORKFLOW_SHORTLIST_FILTER_REL_EXP,
  WORKFLOW_SHORTLIST_MIN_TOTAL_EXP,
  WORKFLOW_SHORTLIST_MAX_TOTAL_EXP,
  WORKFLOW_SHORTLIST_FILTER_TOTAL_EXP,
  WORKFLOW_SHORTLIST_SET_TABLE_SELECTED_ROWS,
  RESET_WORKFLOW_SHORTLIST,
  FILTER_WORKFLOW_SHORTLIST_STATUS,
  WORKFLOW_SHORTLIST_SET_HASDONE_FILTER,
  WORKFLOW_SHORTLIST_SET_MATCHING_FILTER,
  FILTER_WORKFLOW_SHORTLIST_AVAILABLE_HOURS,
  VIEW_WORKFLOW_CANDIDATE_TIMELINE,
  FILTER_WORKFLOW_SHORTLIST_ACTIVE_PROJECTS,
  FILTER_WORKFLOW_SHORTLIST_FLEXIBEES_SELECTED,
} from "../actions/workflowShortlistAction";

const initialState = {
  workflowShortlistList: [],
  workflowShortlistPricingHistory: [],
  workflowShortlistSearchText: "",
  workflowShortlistAppliedRolesFilter: [],
  workflowShortlistAppliedSkillsFilter: [],
  workflowShortlistAppliedStatusFilter: [],
  workflowShortlistAppliedAvailableHoursFilter: [],
  workflowShortlistTotalResults: null,
  workflowShortlistCurrentPage: 1,
  workflowShortlistResultsPerPage: 10,
  workflowShortlistTotalPages: null,
  workflowShortlistPageIds: [],
  workflowShortlistRolesFilter: [],
  workflowShortlistSkillsFilter: [],
  workflowShortlistAvailableHoursFilter: [],
  fetchingData: true,
  workflowShortlistAppliedRelExpFilterState: false,

  workflowShortlistAppliedMinRelExpFilter: null,
  workflowShortlistAppliedMaxRelExpFilter: null,
  workflowShortlistAppliedMinTotalExpFilter: null,
  workflowShortlistAppliedMaxTotalExpFilter: null,

  workflowShortlistTableSelectedRows: [],
  workflowShortlistAppliedMatchingFilter: "0",
  workflowShortlistAppliedHasDoneFilter: [],

  candidatesListFilterFlexiSelected: [],
  candidatesListFilterActiveProjects: [],
  candidatesListApplaiedFilterFlexiSelected: [],
  candidatesListApplaiedFilterActiveProjects: [],
};

const workflowShortlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFLOW_SHORTLIST: {
      return {
        ...state,
        workflowShortlistList: action.payload.result.results,
        workflowShortlistTotalResults: action.payload.result.total_results,
        workflowShortlistTotalPages: action.payload.result.total_pages,
        workflowShortlistPageIds: action.payload.result.page_ids,
        workflowShortlistRolesFilter: action.payload.filter_data.roles,
        workflowShortlistSkillsFilter: action.payload.filter_data.skills,
        workflowShortlistAvailableHoursFilter:
          action.payload.filter_data.total_available_hours,
        candidatesListFilterFlexiSelected:
          action.payload.filter_data.flexibees_selected || [],
        candidatesListFilterActiveProjects:
          action.payload.filter_data.active_projects || [],
        fetchingData: false,
      };
    }
    case CHANGE_CURRENT_WORKFLOW_SHORTLIST_PAGE: {
      return {
        ...state,
        workflowShortlistCurrentPage: action.payload,
      };
    }
    case SET_WORKFLOW_SHORTLIST_ITEMS_PER_PAGE: {
      return {
        ...state,
        workflowShortlistResultsPerPage: action.payload,
      };
    }
    case SEARCH_WORKFLOW_SHORTLIST: {
      return {
        ...state,
        workflowShortlistSearchText: action.payload,
      };
    }
    case VIEW_WORKFLOW_CANDIDATE_TIMELINE: {
      return {
        ...state,
        candidateTimeline: action.payload,
      };
    }
    case FILTER_WORKFLOW_SHORTLIST_ROLES: {
      return {
        ...state,
        workflowShortlistAppliedRolesFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_SHORTLIST_SKILLS: {
      return {
        ...state,
        workflowShortlistAppliedSkillsFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_SHORTLIST_AVAILABLE_HOURS: {
      return {
        ...state,
        workflowShortlistAppliedAvailableHoursFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_SHORTLIST_STATUS: {
      return {
        ...state,
        workflowShortlistAppliedStatusFilter: action.payload,
      };
    }

    case WORKFLOW_SHORTLIST_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }

    case WORKFLOW_SHORTLIST_MIN_REL_EXP: {
      return {
        ...state,
        workflowShortlistAppliedMinRelExpFilter: action.payload,
      };
    }

    case WORKFLOW_SHORTLIST_MAX_REL_EXP: {
      return {
        ...state,
        workflowShortlistAppliedMaxRelExpFilter: action.payload,
      };
    }
    case WORKFLOW_SHORTLIST_FILTER_REL_EXP: {
      return {
        ...state,
        workflowShortlistAppliedRelExpFilterState: action.payload,
      };
    }
    case WORKFLOW_SHORTLIST_MIN_TOTAL_EXP: {
      return {
        ...state,
        workflowShortlistAppliedMinTotalExpFilter: action.payload,
      };
    }

    case WORKFLOW_SHORTLIST_MAX_TOTAL_EXP: {
      return {
        ...state,
        workflowShortlistAppliedMaxTotalExpFilter: action.payload,
      };
    }
    case WORKFLOW_SHORTLIST_FILTER_TOTAL_EXP: {
      return {
        ...state,
        workflowShortlistAppliedTotalExpFilterState: action.payload,
      };
    }
    case WORKFLOW_SHORTLIST_SET_TABLE_SELECTED_ROWS: {
      return {
        ...state,
        workflowShortlistTableSelectedRows: action.payload,
      };
    }
    case RESET_WORKFLOW_SHORTLIST: {
      return {
        ...state,
        fetchingData: true,
        workflowShortlistList: [],
        workflowShortlistCurrentPage: 1,
        workflowShortlistResultsPerPage: 10,
        workflowShortlistTotalPages: null,
        workflowShortlistSearchText: "",
        workflowShortlistTableSelectedRows: [],
        workflowShortlistAppliedRolesFilter: [],
        workflowShortlistAppliedSkillsFilter: [],
        workflowShortlistAppliedStatusFilter: [],
        workflowShortlistAppliedAvailableHoursFilter: [],
        workflowShortlistAppliedRelExpFilterState: false,
        workflowShortlistAppliedMinRelExpFilter: null,
        workflowShortlistAppliedMaxRelExpFilter: null,
        workflowShortlistAppliedMinTotalExpFilter: null,
        workflowShortlistAppliedMaxTotalExpFilter: null,
        candidatesListApplaiedFilterFlexiSelected: [],
        candidatesListApplaiedFilterActiveProjects: [],
        workflowShortlistAppliedMatchingFilter: "0",
        workflowShortlistAppliedHasDoneFilter: [],
      };
    }
    case WORKFLOW_SHORTLIST_SET_HASDONE_FILTER: {
      return {
        ...state,
        workflowShortlistAppliedHasDoneFilter: action.payload,
      };
    }
    case WORKFLOW_SHORTLIST_SET_MATCHING_FILTER: {
      return {
        ...state,
        workflowShortlistAppliedMatchingFilter: action.payload,
      };
    }
    case FILTER_WORKFLOW_SHORTLIST_FLEXIBEES_SELECTED: {
      return {
        ...state,
        candidatesListApplaiedFilterFlexiSelected: action.payload,
      };
    }

    case FILTER_WORKFLOW_SHORTLIST_ACTIVE_PROJECTS: {
      return {
        ...state,
        candidatesListApplaiedFilterActiveProjects: action.payload,
      };
    }
    default:
      return state;
  }
};

export default workflowShortlistReducer;
