import { VIEW_ASSIGN_PROJECT_INFO } from "../actions/assignRecruiterAction";

const initialState = {
  projectInfo: {},
};

const assignRecruiterReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_ASSIGN_PROJECT_INFO: {
      return {
        ...state,
        projectInfo: action.payload,
      };
    }
    default:
      return state;
  }
};

export default assignRecruiterReducer;
