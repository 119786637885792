import {
  GET_WORKFLOW_ASSIGNMENT,
  WORKFLOW_ASSIGNMENT_LOADING,
  SET_WORKFLOW_ASSIGNMENT_ITEMS_PER_PAGE,
  CHANGE_CURRENT_WORKFLOW_ASSIGNMENT_PAGE,
  SEARCH_WORKFLOW_ASSIGNMENT,
  FILTER_WORKFLOW_ASSIGNMENT_ROLES,
  FILTER_WORKFLOW_ASSIGNMENT_SKILLS,
  WORKFLOW_ASSIGNMENT_MIN_REL_EXP,
  WORKFLOW_ASSIGNMENT_MAX_REL_EXP,
  WORKFLOW_ASSIGNMENT_FILTER_REL_EXP,
  WORKFLOW_ASSIGNMENT_MIN_TOTAL_EXP,
  WORKFLOW_ASSIGNMENT_MAX_TOTAL_EXP,
  WORKFLOW_ASSIGNMENT_FILTER_TOTAL_EXP,
  WORKFLOW_ASSIGNMENT_SET_TABLE_SELECTED_ROWS,
  RESET_WORKFLOW_ASSIGNMENT,
  FILTER_WORKFLOW_ASSIGNMENT_STATUS,
  WORKFLOW_ASSIGNMENT_SET_HASDONE_FILTER,
  WORKFLOW_ASSIGNMENT_SET_MATCHING_FILTER,
  WORKFLOW_ASSIGNMENT_VIEW_FUNCTIONAL_FEEDBACK,
  WORKFLOW_ASSIGNMENT_VIEW_ASSIGNMENT_FEEDBACK,
  FILTER_WORKFLOW_ASSIGNMENT_AVAILABLE_HOURS,
  VIEW_WORKFLOW_CANDIDATE_TIMELINE,
  FILTER_WORKFLOW_ASSIGNMENT_FLEXIBEES_SELECTED,
  FILTER_WORKFLOW_ASSIGNMENT_ACTIVE_PROJECTS,
} from "../actions/workflowAssignmentAction";

const initialState = {
  workflowAssignmentList: [],
  workflowAssignmentPricingHistory: [],
  workflowAssignmentSearchText: "",
  workflowAssignmentAppliedRolesFilter: [],
  workflowAssignmentAppliedSkillsFilter: [],
  workflowAssignmentAppliedStatusFilter: [],
  workflowAssignmentAppliedAvailableHoursFilter: [],
  workflowAssignmentTotalResults: null,
  workflowAssignmentCurrentPage: 1,
  workflowAssignmentResultsPerPage: 10,
  workflowAssignmentTotalPages: null,
  workflowAssignmentPageIds: [],
  workflowAssignmentRolesFilter: [],
  workflowAssignmentSkillsFilter: [],
  workflowAssignmentAvailableHoursFilter: [],
  fetchingData: true,
  workflowAssignmentAppliedRelExpFilterState: false,

  workflowAssignmentAppliedMinRelExpFilter: null,
  workflowAssignmentAppliedMaxRelExpFilter: null,
  workflowAssignmentAppliedMinTotalExpFilter: null,
  workflowAssignmentAppliedMaxTotalExpFilter: null,

  workflowAssignmentTableSelectedRows: [],
  workflowAssignmentAppliedMatchingFilter: "0",
  workflowAssignmentAppliedHasDoneFilter: [],

  workflowAssignmentFunctionalInterviewDetails: {},
  workflowAssignmentInterviewDetails: {},

  candidatesListFilterFlexiSelected: [],
  candidatesListFilterActiveProjects: [],
  candidatesListApplaiedFilterFlexiSelected: [],
  candidatesListApplaiedFilterActiveProjects: [],
};

const workflowAssignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFLOW_ASSIGNMENT: {
      return {
        ...state,
        workflowAssignmentList: action.payload.result.results,
        workflowAssignmentTotalResults: action.payload.result.total_results,
        workflowAssignmentTotalPages: action.payload.result.total_pages,
        workflowAssignmentPageIds: action.payload.result.page_ids,
        workflowAssignmentRolesFilter: action.payload.filter_data.roles,
        workflowAssignmentSkillsFilter: action.payload.filter_data.skills,
        workflowAssignmentAvailableHoursFilter:
          action.payload.filter_data.total_available_hours,
        candidatesListFilterFlexiSelected:
          action.payload.filter_data.flexibees_selected || [],
        candidatesListFilterActiveProjects:
          action.payload.filter_data.active_projects || [],
        fetchingData: false,
      };
    }
    case CHANGE_CURRENT_WORKFLOW_ASSIGNMENT_PAGE: {
      return {
        ...state,
        workflowAssignmentCurrentPage: action.payload,
      };
    }
    case VIEW_WORKFLOW_CANDIDATE_TIMELINE: {
      return {
        ...state,
        candidateTimeline: action.payload,
      };
    }
    case SET_WORKFLOW_ASSIGNMENT_ITEMS_PER_PAGE: {
      return {
        ...state,
        workflowAssignmentResultsPerPage: action.payload,
      };
    }
    case SEARCH_WORKFLOW_ASSIGNMENT: {
      return {
        ...state,
        workflowAssignmentSearchText: action.payload,
      };
    }

    case FILTER_WORKFLOW_ASSIGNMENT_ROLES: {
      return {
        ...state,
        workflowAssignmentAppliedRolesFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_ASSIGNMENT_SKILLS: {
      return {
        ...state,
        workflowAssignmentAppliedSkillsFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_ASSIGNMENT_AVAILABLE_HOURS: {
      return {
        ...state,
        workflowAssignmentAppliedAvailableHoursFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_ASSIGNMENT_STATUS: {
      return {
        ...state,
        workflowAssignmentAppliedStatusFilter: action.payload,
      };
    }

    case WORKFLOW_ASSIGNMENT_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }

    case WORKFLOW_ASSIGNMENT_MIN_REL_EXP: {
      return {
        ...state,
        workflowAssignmentAppliedMinRelExpFilter: action.payload,
      };
    }

    case WORKFLOW_ASSIGNMENT_MAX_REL_EXP: {
      return {
        ...state,
        workflowAssignmentAppliedMaxRelExpFilter: action.payload,
      };
    }
    case WORKFLOW_ASSIGNMENT_FILTER_REL_EXP: {
      return {
        ...state,
        workflowAssignmentAppliedRelExpFilterState: action.payload,
      };
    }
    case WORKFLOW_ASSIGNMENT_MIN_TOTAL_EXP: {
      return {
        ...state,
        workflowAssignmentAppliedMinTotalExpFilter: action.payload,
      };
    }

    case WORKFLOW_ASSIGNMENT_MAX_TOTAL_EXP: {
      return {
        ...state,
        workflowAssignmentAppliedMaxTotalExpFilter: action.payload,
      };
    }
    case WORKFLOW_ASSIGNMENT_FILTER_TOTAL_EXP: {
      return {
        ...state,
        workflowAssignmentAppliedTotalExpFilterState: action.payload,
      };
    }
    case WORKFLOW_ASSIGNMENT_SET_TABLE_SELECTED_ROWS: {
      return {
        ...state,
        workflowAssignmentTableSelectedRows: action.payload,
      };
    }
    case RESET_WORKFLOW_ASSIGNMENT: {
      return {
        ...state,
        fetchingData: true,
        workflowAssignmentList: [],
        workflowAssignmentCurrentPage: 1,
        workflowAssignmentResultsPerPage: 10,
        workflowAssignmentTotalPages: null,
        workflowAssignmentSearchText: "",
        workflowAssignmentTableSelectedRows: [],
        workflowAssignmentAppliedRolesFilter: [],
        workflowAssignmentAppliedSkillsFilter: [],
        workflowAssignmentAppliedStatusFilter: [],
        workflowAssignmentAppliedAvailableHoursFilter: [],
        workflowAssignmentAppliedRelExpFilterState: false,
        workflowAssignmentAppliedMinRelExpFilter: null,
        workflowAssignmentAppliedMaxRelExpFilter: null,
        workflowAssignmentAppliedMinTotalExpFilter: null,
        workflowAssignmentAppliedMaxTotalExpFilter: null,
        workflowAssignmentAppliedMatchingFilter: "0",
        workflowAssignmentAppliedHasDoneFilter: [],
        candidatesListApplaiedFilterFlexiSelected: [],
        candidatesListApplaiedFilterActiveProjects: [],
      };
    }
    case WORKFLOW_ASSIGNMENT_SET_HASDONE_FILTER: {
      return {
        ...state,
        workflowAssignmentAppliedHasDoneFilter: action.payload,
      };
    }
    case WORKFLOW_ASSIGNMENT_SET_MATCHING_FILTER: {
      return {
        ...state,
        workflowAssignmentAppliedMatchingFilter: action.payload,
      };
    }
    case WORKFLOW_ASSIGNMENT_VIEW_FUNCTIONAL_FEEDBACK: {
      return {
        ...state,
        workflowAssignmentFunctionalInterviewDetails: action.payload,
      };
    }
    case WORKFLOW_ASSIGNMENT_VIEW_ASSIGNMENT_FEEDBACK: {
      return {
        ...state,
        workflowAssignmentInterviewDetails: action.payload,
      };
    }
    case FILTER_WORKFLOW_ASSIGNMENT_FLEXIBEES_SELECTED: {
      return {
        ...state,
        candidatesListApplaiedFilterFlexiSelected: action.payload,
      };
    }

    case FILTER_WORKFLOW_ASSIGNMENT_ACTIVE_PROJECTS: {
      return {
        ...state,
        candidatesListApplaiedFilterActiveProjects: action.payload,
      };
    }
    default:
      return state;
  }
};

export default workflowAssignmentReducer;
