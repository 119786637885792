export const ROLE_TYPES = [
  {
    id: 1,
    name: "Role",
    value: "Role",
  },
  {
    id: 2,
    name: "Project",
    value: "Project",
  },
];

export const MODEL_TYPES = [
  {
    id: 1,
    name: "Resourcing",
    value: "Resourcing",
  },
  {
    id: 2,
    name: "Placement",
    value: "Placement",
  },
  {
    id: 3,
    name: "Hours Based",
    value: "Hours Based",
  },
  {
    id: 4,
    name: "Output Based",
    value: "Output Based",
  },
  {
    id: 5,
    name: "To be assessed",
    value: "To be assessed",
  },
];

export const STAGE = [
  {
    id: 1,
    name: "Information",
    value: "Information",
  },
  {
    id: 2,
    name: "Profile Creation",
    value: "Profile Creation",
  },
  {
    id: 3,
    name: "Profile Sent",
    value: "Profile Sent",
  },
  {
    id: 4,
    name: "Proposal Creation",
    value: "Proposal Creation",
  },
  {
    id: 5,
    name: "Proposal Sent",
    value: "Proposal Sent",
  },
  {
    id: 6,
    name: "Negotiation",
    value: "Negotiation",
  },
  {
    id: 7,
    name: "Proposal Closed",
    value: "Proposal Closed",
  },
  {
    id: 8,
    name: "Recruitment Working",
    value: "Recruitment Working",
  },
  {
    id: 9,
    name: "Client Interview",
    value: "Client Interview",
  },
  {
    id: 10,
    name: "Recruitment Closure",
    value: "Recruitment Closure",
  },
  {
    id: 11,
    name: "Launch",
    value: "Launch",
  },
  {
    id: 12,
    name: "Didn't Work",
    value: "Didn't Work",
  },
  {
    id: 13,
    name: "Limbo",
    value: "Limbo",
  },
];
export const FLEXI_DETAILS = [
  {
    id: 1,
    name: "Part-time fully remote",
    value: "Part-time fully remote",
  },
  {
    id: 2,
    name: "Part-time with once in a while training/meeting",
    value: "Part-time with once in a while training/meeting",
  },
  {
    id: 3,
    name: "Part-time with meeting 1 day a week ",
    value: "Part-time with meeting 1 day a week ",
  },
  {
    id: 4,
    name: "Part-time with meetings 2-3 days a week",
    value: "Part-time with meetings 2-3 days a week",
  },
  {
    id: 5,
    name: "Part-time fully work-from-office",
    value: "Part-time fully work-from-office",
  },
  {
    id: 6,
    name: "Full-time fully remote",
    value: "Full-time fully remote",
  },
  {
    id: 7,
    name: "Full-time with once in a while training/meeting",
    value: "Full-time with once in a while training/meeting",
  },
  {
    id: 8,
    name: "Full-time with meeting 1 day a week ",
    value: "Full-time with meeting 1 day a week ",
  },
  {
    id: 9,
    name: "Full-time with meetings 2-3 days a week",
    value: "Full-time with meetings 2-3 days a week",
  },
  {
    id: 10,
    name: "Full-time fully work-from-office",
    value: "Full-time fully work-from-office",
  },
  {
    id: 11,
    name: "Don't know yet",
    value: "Don't know yet",
  },
];
export const SALE_TYPE = [
  {
    id: 1,
    name: "Field Sales",
    value: "Field Sales",
  },
  {
    id: 2,
    name: "Inside Sales",
    value: "Inside Sales",
  },
  {
    id: 3,
    name: "End to End Conversion",
    value: "End to End Conversion",
  },
  {
    id: 4,
    name: "Meeting Setup",
    value: "Meeting Setup",
  },
  {
    id: 5,
    name: "Customer Service",
    value: "Customer Service",
  },
];
export const POSITIONS = [
  {
    id: 1,
    name: "1",
    value: "1",
  },
  {
    id: 2,
    name: "2",
    value: "2",
  },
  {
    id: 3,
    name: "3",
    value: "3",
  },
  {
    id: 4,
    name: "4",
    value: "4",
  },
  {
    id: 5,
    name: "5",
    value: "5",
  },
  {
    id: 6,
    name: "6",
    value: "6",
  },
  {
    id: 7,
    name: "7",
    value: "7",
  },
  {
    id: 8,
    name: "8",
    value: "8",
  },
  {
    id: 9,
    name: "9",
    value: "9",
  },
  {
    id: 10,
    name: "10",
    value: "10",
  },
  {
    id: 15,
    name: "15",
    value: "15",
  },
  {
    id: 20,
    name: "20",
    value: "20",
  },
];

export const EDUCATION_LIST = [
  {
    id: 1,
    name: "High School",
    value: "High School",
  },
  {
    id: 2,
    name: "Graduate",
    value: "Graduate",
  },
  {
    id: 3,
    name: "Post Graduate",
    value: "Post Graduate",
  },
  {
    id: 4,
    name: "Doctrate",
    value: "Doctorate",
  },
];

export const EXPERIANCE_MIN_MAX = (min = 0, max) => {
  let min_max = [];
  for (let i = +min; i <= +max; i++) {
    min_max.push({
      id: i + "",
      name: i + "",
      value: i + "",
    });
  }
  return min_max;
};

export const TARGET_AUDIENCE = [
  {
    id: 1,
    name: "B2B",
    value: "B2B",
  },
  {
    id: 2,
    name: "B2C",
    value: "B2C",
  },
  {
    id: 3,
    name: "Both B2B and B2C",
    value: "Both B2B and B2c",
  },
];

export const YES_NO = [
  {
    id: 1,
    name: "Yes",
    value: "Yes",
  },
  {
    id: 2,
    name: "No",
    value: "No",
  },
];

export const MIN_MAX = (min = 0, max, thortle = 1) => {
  let min_max = [];
  for (let i = min; i <= max; i += thortle) {
    min_max.push({
      id: i,
      name: i,
      value: i,
    });
  }

  return min_max;
};

export const MIN_MAX_HOURS = (min = 0, type) => {
  let min_max = [];
  let max =
    type === "days" ? 8 : type === "weeks" ? 40 : type === "months" ? 200 : 8;
  for (let i = min; i <= max; i++) {
    min_max.push({
      id: i,
      name: i,
      value: i,
    });
  }

  return min_max;
};

export const TRAVEL_OFTEN = [
  {
    id: 1,
    name: "All Days",
    value: "All Days",
  },
  {
    id: 2,
    name: "2-3 days per week",
    value: "2-3 days per week",
  },
  {
    id: 3,
    name: "Once a week",
    value: "Once a week",
  },
  {
    id: 4,
    name: "Once in 2-weeks or a month",
    value: "Once in 2-weeks or a month",
  },
  {
    id: 5,
    name: "In the beginning for training",
    value: "In the beginning for training",
  },
  {
    id: 6,
    name: "As needed for customer meetings",
    value: "As needed for customer meetings",
  },
  {
    id: 7,
    name: "Fully Remote",
    value: "Fully Remote",
  },
];

export const DURATION_UNITS = [
  {
    id: 1,
    name: "Days",
    value: "days",
  },
  {
    id: 2,
    name: "Weeks",
    value: "weeks",
  },
  {
    id: 3,
    name: "Months",
    value: "months",
  },
  // {
  //   id: 4,
  //   name: "Year",
  //   value: "Year",
  // },
];

export const TYPE_OF_PAYOUT = [
  {
    id: 1,
    name: "Hourly",
    value: "hourly",
  },
  {
    id: 2,
    name: "Weekly",
    value: "weekly",
  },
  {
    id: 3,
    name: "Monthly",
    value: "monthly",
  },
  {
    id: 4,
    name: "Yearly",
    value: "yearly",
  },
];

export const WORKING_HOURS_UNITS = [
  {
    id: 1,
    name: "In a Day",
    value: "day",
  },
  {
    id: 2,
    name: "In a Week",
    value: "week",
  },
  {
    id: 3,
    name: "In a Month",
    value: "month",
  },
  // {
  //   id: 4,
  //   name: "Year",
  //   value: "Year",
  // },
];

export const PROJECT_DURATION_UNITS = [
  {
    id: 1,
    name: "Days",
    value: "days",
  },
  {
    id: 2,
    name: "Weeks",
    value: "weeks",
  },
  {
    id: 3,
    name: "Months",
    value: "months",
  },
  {
    id: 4,
    name: "Years",
    value: "years",
  },
];

export const WORD_UNITS = [
  {
    id: 1,
    name: "Page",
    value: "Page",
  },
  {
    id: 2,
    name: "Article",
    value: "Article",
  },
];
export const TYPE_OF_CONTENT = [
  {
    id: 1,
    name: "Blogposts/Articles",
    value: "Blogposts/Articles",
  },
  {
    id: 2,
    name: "Social Media Posts",
    value: "Social Media Posts",
  },
  {
    id: 3,
    name: "Ad Copy",
    value: "Ad Copy",
  },
  {
    id: 4,
    name: "White Papers",
    value: "White Papers",
  },
  {
    id: 5,
    name: "Website Content",
    value: "Website Content",
  },
  {
    id: 6,
    name: "News Letters",
    value: "News Letters",
  },
  {
    id: 7,
    name: "Others",
    value: "Others",
  },
];

export const COMMUNICATION_SKILL_LEVEL = [
  {
    id: 1,
    name: "Average",
    value: "average",
  },
  {
    id: 2,
    name: "Good",
    value: "good",
  },
  {
    id: 3,
    name: "Excellent",
    value: "excellent",
  },
];
