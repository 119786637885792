import {
  GET_WORKFLOW_FLEXIFIT,
  WORKFLOW_FLEXIFIT_LOADING,
  SET_WORKFLOW_FLEXIFIT_ITEMS_PER_PAGE,
  CHANGE_CURRENT_WORKFLOW_FLEXIFIT_PAGE,
  SEARCH_WORKFLOW_FLEXIFIT,
  FILTER_WORKFLOW_FLEXIFIT_ROLES,
  FILTER_WORKFLOW_FLEXIFIT_SKILLS,
  WORKFLOW_FLEXIFIT_MIN_REL_EXP,
  WORKFLOW_FLEXIFIT_MAX_REL_EXP,
  WORKFLOW_FLEXIFIT_FILTER_REL_EXP,
  WORKFLOW_FLEXIFIT_MIN_TOTAL_EXP,
  WORKFLOW_FLEXIFIT_MAX_TOTAL_EXP,
  WORKFLOW_FLEXIFIT_FILTER_TOTAL_EXP,
  WORKFLOW_FLEXIFIT_SET_TABLE_SELECTED_ROWS,
  RESET_WORKFLOW_FLEXIFIT,
  FILTER_WORKFLOW_FLEXIFIT_STATUS,
  WORKFLOW_FLEXIFIT_SET_HASDONE_FILTER,
  WORKFLOW_FLEXIFIT_SET_MATCHING_FILTER,
  WORKFLOW_FLEXIFIT_VIEW_CLIENT_FEEDBACK,
  WORKFLOW_FLEXIFIT_VIEW_FLEXIFIT_FEEDBACK,
  FILTER_WORKFLOW_FLEXIFIT_AVAILABLE_HOURS,
  VIEW_WORKFLOW_CANDIDATE_TIMELINE,
  FILTER_WORKFLOW_FLEXIFIT_FLEXIBEES_SELECTED,
  FILTER_WORKFLOW_FLEXIFIT_ACTIVE_PROJECTS,
} from "../actions/workflowFlexifitAction";

const initialState = {
  workflowFlexifitList: [],
  workflowFlexifitPricingHistory: [],
  workflowFlexifitSearchText: "",
  workflowFlexifitAppliedRolesFilter: [],
  workflowFlexifitAppliedSkillsFilter: [],
  workflowFlexifitAppliedStatusFilter: [],
  workflowFlexifitAppliedAvailableHoursFilter: [],
  workflowFlexifitTotalResults: null,
  workflowFlexifitCurrentPage: 1,
  workflowFlexifitResultsPerPage: 10,
  workflowFlexifitTotalPages: null,
  workflowFlexifitPageIds: [],
  workflowFlexifitRolesFilter: [],
  workflowFlexifitSkillsFilter: [],
  workflowFlexifitAvailableHoursFilter: [],
  fetchingData: true,
  workflowFlexifitAppliedRelExpFilterState: false,

  workflowFlexifitAppliedMinRelExpFilter: null,
  workflowFlexifitAppliedMaxRelExpFilter: null,
  workflowFlexifitAppliedMinTotalExpFilter: null,
  workflowFlexifitAppliedMaxTotalExpFilter: null,

  workflowFlexifitTableSelectedRows: [],
  workflowFlexifitAppliedMatchingFilter: "0",
  workflowFlexifitAppliedHasDoneFilter: [],

  workflowClientDetails: {},
  workflowFlexifitInterviewDetails: {},

  candidatesListFilterFlexiSelected: [],
  candidatesListFilterActiveProjects: [],
  candidatesListApplaiedFilterFlexiSelected: [],
  candidatesListApplaiedFilterActiveProjects: [],
};

const workflowFlexifitReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFLOW_FLEXIFIT: {
      return {
        ...state,
        workflowFlexifitList: action.payload.result.results,
        workflowFlexifitTotalResults: action.payload.result.total_results,
        workflowFlexifitTotalPages: action.payload.result.total_pages,
        workflowFlexifitPageIds: action.payload.result.page_ids,
        workflowFlexifitRolesFilter: action.payload.filter_data.roles,
        workflowFlexifitSkillsFilter: action.payload.filter_data.skills,
        workflowFlexifitAvailableHoursFilter:
          action.payload.filter_data.total_available_hours,
        candidatesListFilterFlexiSelected:
          action.payload.filter_data.flexibees_selected || [],
        candidatesListFilterActiveProjects:
          action.payload.filter_data.active_projects || [],
        fetchingData: false,
      };
    }
    case CHANGE_CURRENT_WORKFLOW_FLEXIFIT_PAGE: {
      return {
        ...state,
        workflowFlexifitCurrentPage: action.payload,
      };
    }
    case VIEW_WORKFLOW_CANDIDATE_TIMELINE: {
      return {
        ...state,
        candidateTimeline: action.payload,
      };
    }
    case SET_WORKFLOW_FLEXIFIT_ITEMS_PER_PAGE: {
      return {
        ...state,
        workflowFlexifitResultsPerPage: action.payload,
      };
    }
    case SEARCH_WORKFLOW_FLEXIFIT: {
      return {
        ...state,
        workflowFlexifitSearchText: action.payload,
      };
    }

    case FILTER_WORKFLOW_FLEXIFIT_ROLES: {
      return {
        ...state,
        workflowFlexifitAppliedRolesFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_FLEXIFIT_SKILLS: {
      return {
        ...state,
        workflowFlexifitAppliedSkillsFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_FLEXIFIT_AVAILABLE_HOURS: {
      return {
        ...state,
        workflowFlexifitAppliedAvailableHoursFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_FLEXIFIT_STATUS: {
      return {
        ...state,
        workflowFlexifitAppliedStatusFilter: action.payload,
      };
    }

    case WORKFLOW_FLEXIFIT_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }

    case WORKFLOW_FLEXIFIT_MIN_REL_EXP: {
      return {
        ...state,
        workflowFlexifitAppliedMinRelExpFilter: action.payload,
      };
    }

    case WORKFLOW_FLEXIFIT_MAX_REL_EXP: {
      return {
        ...state,
        workflowFlexifitAppliedMaxRelExpFilter: action.payload,
      };
    }
    case WORKFLOW_FLEXIFIT_FILTER_REL_EXP: {
      return {
        ...state,
        workflowFlexifitAppliedRelExpFilterState: action.payload,
      };
    }
    case WORKFLOW_FLEXIFIT_MIN_TOTAL_EXP: {
      return {
        ...state,
        workflowFlexifitAppliedMinTotalExpFilter: action.payload,
      };
    }

    case WORKFLOW_FLEXIFIT_MAX_TOTAL_EXP: {
      return {
        ...state,
        workflowFlexifitAppliedMaxTotalExpFilter: action.payload,
      };
    }
    case WORKFLOW_FLEXIFIT_FILTER_TOTAL_EXP: {
      return {
        ...state,
        workflowFlexifitAppliedTotalExpFilterState: action.payload,
      };
    }
    case WORKFLOW_FLEXIFIT_SET_TABLE_SELECTED_ROWS: {
      return {
        ...state,
        workflowFlexifitTableSelectedRows: action.payload,
      };
    }
    case RESET_WORKFLOW_FLEXIFIT: {
      return {
        ...state,
        fetchingData: true,
        workflowFlexifitList: [],
        workflowFlexifitCurrentPage: 1,
        workflowFlexifitResultsPerPage: 10,
        workflowFlexifitTotalPages: null,
        workflowFlexifitSearchText: "",
        workflowFlexifitTableSelectedRows: [],
        workflowFlexifitAppliedRolesFilter: [],
        workflowFlexifitAppliedSkillsFilter: [],
        workflowFlexifitAppliedStatusFilter: [],
        workflowFlexifitAppliedAvailableHoursFilter: [],
        workflowFlexifitAppliedRelExpFilterState: false,
        workflowFlexifitAppliedMinRelExpFilter: null,
        workflowFlexifitAppliedMaxRelExpFilter: null,
        workflowFlexifitAppliedMinTotalExpFilter: null,
        workflowFlexifitAppliedMaxTotalExpFilter: null,
        workflowFlexifitAppliedMatchingFilter: "0",
        workflowFlexifitAppliedHasDoneFilter: [],
        candidatesListApplaiedFilterFlexiSelected: [],
        candidatesListApplaiedFilterActiveProjects: [],
      };
    }
    case WORKFLOW_FLEXIFIT_SET_HASDONE_FILTER: {
      return {
        ...state,
        workflowFlexifitAppliedHasDoneFilter: action.payload,
      };
    }
    case WORKFLOW_FLEXIFIT_SET_MATCHING_FILTER: {
      return {
        ...state,
        workflowFlexifitAppliedMatchingFilter: action.payload,
      };
    }
    case WORKFLOW_FLEXIFIT_VIEW_CLIENT_FEEDBACK: {
      return {
        ...state,
        workflowClientDetails: action.payload,
      };
    }
    case WORKFLOW_FLEXIFIT_VIEW_FLEXIFIT_FEEDBACK: {
      return {
        ...state,
        workflowFlexifitInterviewDetails: action.payload,
      };
    }
    case FILTER_WORKFLOW_FLEXIFIT_FLEXIBEES_SELECTED: {
      return {
        ...state,
        candidatesListApplaiedFilterFlexiSelected: action.payload,
      };
    }

    case FILTER_WORKFLOW_FLEXIFIT_ACTIVE_PROJECTS: {
      return {
        ...state,
        candidatesListApplaiedFilterActiveProjects: action.payload,
      };
    }
    default:
      return state;
  }
};

export default workflowFlexifitReducer;
