import { CHANGE_VIEW_PROJECT_TAB } from "../actions/viewProjectTabAction";

const initialState = {
  initialTab: 0,
  tabValues: {},
};

const viewProjectTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_VIEW_PROJECT_TAB: {
      return {
        ...state,
        initialTab: action.payload,
      };
    }
    default:
      return state;
  }
};

export default viewProjectTabReducer;
