import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosMediaUpload,
  axiosPatchWithToken,
  axiosPost,
  axiosPut,
} from "../services/apiServices";
import {
  saveLocalStorage,
  removeLocalStorage,
  retrieveLocalStorage,
} from "@flexibees/core";
import {
  ERROR_ALERT,
  INFO_ALERT,
  SUCCESS_ALERT,
  WARNING_ALERT,
} from "./commonAction";

const adminRole = "admin";

export const LOGIN = "LOGIN";
export const AUTHENTICATE = "AUTHENTICATE";
export const SET_DID_TRY_AL = "SET_DID_TRY_AL";
export const LOGOUT = "LOGOUT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const USER_INFO = "USER_INFO";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const login = (emailId, password, redirectUser) => {
  const body = {
    email: emailId,
    password: password,
    admin_role: adminRole,
  };

  return async (dispatch) => {
    axiosPost(API.LOGIN, body).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: LOGIN,
          payload: res.data.data,
        });
        saveDataToLocal(res.data.data.user);
        saveTokenToLocal(res.data.data.token);
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Logged in Successfully",
        });
        redirectUser();
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      }
    });
  };
};

export const forgotPassword = (emailId, admin_role, goBackToLogin) => {
  const body = {
    email: emailId,
    admin_role: admin_role,
  };

  return async (dispatch) => {
    axiosPost(API.FORGOT_PASSWORD, body).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: "",
        });
        dispatch({
          type: SUCCESS_ALERT,
          payload: res.data.data.message,
        });
        goBackToLogin();
      } else if (res.data.status_code === 404) {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
        goBackToLogin();
      }
    });
  };
};

export const changePassword = (current_password, new_password, clearInputs) => {
  const body = {
    old_password: current_password,
    new_password: new_password,
  };

  const token = retrieveLocalStorage("token");

  return async (dispatch) => {
    axiosPut(API.CHANGE_PASSWORD, body, token).then((res) => {
      if (res.data.status_code === 200) {
        if (res.data.data.token) {
          dispatch({
            type: CHANGE_PASSWORD,
            payload: res.data.data,
          });
          saveTokenToLocal(res.data.data.token);
          clearInputs();
          dispatch({
            type: SUCCESS_ALERT,
            payload: "Password updated Successfully",
          });
          setTimeout(() => {
            dispatch({ type: "LOGOUT", token: null, user: {} });
            removeLocalStorage("userData");
            removeLocalStorage("token");
            dispatch({
              type: INFO_ALERT,
              payload: "Please login again to continue",
            });
          }, 1500);
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message,
          });
        }
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      }
    });
  };
};

export const getUserInfo = (token) => {
  return async (dispatch) => {
    axiosGetWithToken(API.VIEW_PROFILE, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: USER_INFO,
          payload: res.data.data,
        });
        saveDataToLocal(res.data.data);
      } else {
        console.log(`Error: ${res.data.data.message}`);
      }
    });
  };
};

export const updateProfile = (data, token) => {
  return async (dispatch) => {
    axiosPatchWithToken(API.EDIT_PROFILE, data, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: UPDATE_PROFILE,
          payload: res.data.data,
        });
        saveDataToLocal(res.data.data);
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Your profile has been updated Successfully",
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      }
    });
  };
};

export const uploadProfilePic = (file_type, file, token, updatePic) => {
  return async (dispatch) => {
    axiosMediaUpload(API.MEDIA_UPLOAD, file_type, file, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: UPDATE_PROFILE,
          payload: { profile_pic: res.data.data.url },
        });
        updatePic(res.data.data);
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Profile picture has uploaded successfully",
        });
        dispatch({
          type: INFO_ALERT,
          payload: "Please Save to update your profile picture",
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      }
    });
  };
};

const saveDataToLocal = (data) => {
  let existingUserInfo = retrieveLocalStorage("userData");
  saveLocalStorage("userData", { ...existingUserInfo, ...data });
};

const saveTokenToLocal = (data) => {
  saveLocalStorage("token", data);
};

export const setDidTryAL = () => {
  return (dispatch) => {
    dispatch({ type: SET_DID_TRY_AL });
  };
};

export const authenticate = (token, user) => {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATE, token: token, user: user });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT", token: null, user: {} });
    removeLocalStorage("userData");
    removeLocalStorage("token");
    dispatch({
      type: INFO_ALERT,
      payload: "You have been logged out successfully",
    });
  };
};

export const sessionLogout = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT", token: null, user: {} });
    removeLocalStorage("userData");
    removeLocalStorage("token");
    dispatch({
      type: WARNING_ALERT,
      payload: "Your session has expired. Please login again.",
    });
  };
};
