import { API } from "../constants/api";
import { axiosGetWithToken, axiosPostWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";

export const VIEW_ASSIGN_PROJECT_INFO = "VIEW_ASSIGN_PROJECT_INFO";

export const viewProjectInfo = (id, dataFetchComplete) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.VIEW_ASSIGN_PROJECT_INFO}/${id}/`, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_ASSIGN_PROJECT_INFO,
            payload: res.data.data,
          });
          dataFetchComplete();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: "Something went wrong.",
          });
          dataFetchComplete();
        }
      }
    );
  };
};

export const suspendProject = (body, goBack) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(`${API.ASSIGN_SUSPEND_PROJECT}`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          goBack();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              res.data.data.message ||
              "Something went wrong. Unable to process request",
          });
        }
      }
    );
  };
};
