import { API } from "../constants/api";
import { axiosPostWithToken, axiosPut } from "../services/apiServices";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";

export const GET_RECRUITERS = "GET_RECRUITERS";
export const RECRUITERS_LOADING = "RECRUITERS_LOADING";
export const SET_RECRUITERS_ITEMS_PER_PAGE = "SET_RECRUITERS_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_RECRUITERS_PAGE = "CHANGE_CURRENT_RECRUITERS_PAGE";
export const SEARCH_RECRUITERS = "SEARCH_RECRUITERS";
export const RECRUITERS_LEVEL_FILTER = "RECRUITERS_LEVEL_FILTER";
export const RECRUITERS_SKILLS_FILTER = "RECRUITERS_SKILLS_FILTER";
export const RECRUITERS_ACTIVE_PROJECTS_FILTER =
  "RECRUITERS_ACTIVE_PROJECTS_FILTER";

export const getRecruiters = (projectId) => {
  return async (dispatch, getstate) => {
    dispatch({
      type: RECRUITERS_LOADING,
    });
    const token = getstate().auth.token;
    const pagesize = getstate().recruiters.recruitersResultsPerPage;
    const page = getstate().recruiters.recruitersCurrentPage;
    const seachText = getstate().recruiters.recruitersSearchText;
    const skills = getstate().recruiters.recruiterAppliedSkillsFilter;
    const level = getstate().recruiters.recruiterAppliedLevelFilter;
    const activeProjects = getstate().recruiters
      .recruitersActiveApplaidProjectFilter;
    const body = {
      search_term: seachText,
      filter_data: {
        level: level,
        skills: skills,
        active_projects: activeProjects,
      },
    };

    axiosPostWithToken(
      `${API.GET_RECRUITERS}/${projectId}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_RECRUITERS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "Something went wrong. Unable to fetch data",
        });
      }
    });
  };
};

export const assignRecruiter = (projectId, recruiterId, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    const body = {
      recruiter: recruiterId,
    };

    dispatch({
      type: RECRUITERS_LOADING,
    });

    axiosPut(`${API.ASSIGN_RECRUITER}/${projectId}/`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SUCCESS_ALERT,
            payload: res.data.data.message,
          });

          refresh();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (res.data && res.data.data && res.data.data.message) ||
              "Something went wrong. Unable to process request.",
          });
        }
      }
    );
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITERS_LOADING,
    });
    dispatch({
      type: SET_RECRUITERS_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITERS_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_RECRUITERS_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITERS_LOADING,
    });
    dispatch({
      type: SEARCH_RECRUITERS,
      payload: value,
    });
  };
};

export const setFilteredLevel = (level) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITERS_LOADING,
    });
    dispatch({
      type: RECRUITERS_LEVEL_FILTER,
      payload: level,
    });
  };
};

export const setFilteredSkills = (skills) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITERS_LOADING,
    });
    dispatch({
      type: RECRUITERS_SKILLS_FILTER,
      payload: skills,
    });
  };
};

export const setActiveProjectsFilter = (activeProjects) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITERS_ACTIVE_PROJECTS_FILTER,
      payload: activeProjects,
    });
  };
};
