import { GET_DYNAMIC_DROPDOWN_FROM_CONFIG } from "../actions/projectDetailAction";
import _ from "lodash";
import {
  SALE_TYPE,
  MODEL_TYPES,
  ROLE_TYPES,
  STAGE,
  EDUCATION_LIST,
  TARGET_AUDIENCE,
  TRAVEL_OFTEN,
  TYPE_OF_CONTENT,
  TYPE_OF_PAYOUT,
} from "../constants/CRMConstants";

const initialState = {
  crmDetails: {},
  projectDetails: {},
  roles: [],
  domains: [],
  skills: [],
  languages: [],
  stageDropdown: [],
  educationDropdown: [],
  saleTypeDropdown: [],
  contentTypeDropdown: [],
  targetAudienceDropdown: [],
  modelTypeDropdown: [],
  howOftenTravellingDropdown: [],
  roleTypeDropdown: [],
};

const projectDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DYNAMIC_DROPDOWN_FROM_CONFIG: {
      return {
        ...state,
        stageDropdown: STAGE,
        educationDropdown:
          _.find(action.payload, { key: "education" }).values.length > 0
            ? _.find(action.payload, { key: "education" }).values
            : EDUCATION_LIST,
        saleTypeDropdown:
          _.find(action.payload, { key: "sale_type" }).values.length > 0
            ? _.find(action.payload, { key: "sale_type" }).values
            : SALE_TYPE,
        contentTypeDropdown:
          _.find(action.payload, { key: "content_type" }).values.length > 0
            ? _.find(action.payload, { key: "content_type" }).values
            : TYPE_OF_CONTENT,
        targetAudienceDropdown:
          _.find(action.payload, {
            key: "target_audience",
          }).values.length > 0
            ? _.find(action.payload, {
                key: "target_audience",
              }).values
            : TARGET_AUDIENCE,
        modelTypeDropdown:
          _.find(action.payload, { key: "model_type" }).values.length > 0
            ? _.find(action.payload, { key: "model_type" }).values
            : MODEL_TYPES,
        howOftenTravellingDropdown: TRAVEL_OFTEN,
        roleTypeDropdown:
          _.find(action.payload, { key: "role_type" }).values.length > 0
            ? _.find(action.payload, { key: "role_type" }).values
            : ROLE_TYPES,
        typeOfPayout:
          _.find(action.payload, { key: "type_of_payout" }).values.length > 0
            ? _.find(action.payload, { key: "type_of_payout" }).values
            : TYPE_OF_PAYOUT,
      };
    }
    default:
      return state;
  }
};

export default projectDetailsReducer;
