import {
  GET_WORKFLOW_ALL_CANDIDATE,
  GET_WORKFLOW_ALL_CANDIDATE_FILTERS,
  WORKFLOW_ALL_CANDIDATE_LOADING,
  SET_WORKFLOW_ALL_CANDIDATE_ITEMS_PER_PAGE,
  CHANGE_CURRENT_WORKFLOW_ALL_CANDIDATE_PAGE,
  SEARCH_WORKFLOW_ALL_CANDIDATE,
  SEARCH_WORKFLOW_ALL_CANDIDATES_FILTERS,
  RESET_WORKFLOW_SEARCH_ALL_CANDIDATES_FILTERS,
  FILTER_WORKFLOW_ALL_CANDIDATE_ROLES,
  FILTER_WORKFLOW_ALL_CANDIDATE_SKILLS,
  WORKFLOW_ALL_CANDIDATE_MIN_REL_EXP,
  WORKFLOW_ALL_CANDIDATE_MAX_REL_EXP,
  WORKFLOW_ALL_CANDIDATE_FILTER_REL_EXP,
  WORKFLOW_ALL_CANDIDATE_MIN_TOTAL_EXP,
  WORKFLOW_ALL_CANDIDATE_MAX_TOTAL_EXP,
  WORKFLOW_ALL_CANDIDATE_FILTER_TOTAL_EXP,
  RESET_WORKFLOW_ALL_CANDIDATE,
  WORKFLOW_ALL_CANDIDATE_SET_TABLE_SELECTED_ROWS,
  WORKFLOW_ALL_CANDIDATE_SET_HASDONE_FILTER,
  WORKFLOW_ALL_CANDIDATE_SET_MATCHING_FILTER,
  FILTER_WORKFLOW_ALL_CANDIDATE_AVAILABLE_HOURS,
  VIEW_WORKFLOW_CANDIDATE_TIMELINE,
  FILTER_WORKFLOW_ALL_CANDIDATE_ACTIVE_PROJECTS,
  FILTER_WORKFLOW_ALL_CANDIDATE_FLEXIBEES_SELECTED,
  RESET_WORKFLOW_ALL_CANDIDATE_SEARCH_FILTERS,
  WORKFLOW_ALL_CANDIDATE_FILTER_MATCHING,
  WORKFLOW_ALL_CANDIDATE_MIN_MATCHING,
  WORKFLOW_ALL_CANDIDATE_MAX_MATCHING,
} from "../actions/workflowAllCandidateAction";

const initialState = {
  workflowAllCandidateList: [],
  workflowAllCandidatePricingHistory: [],
  workflowAllCandidateSearchText: "",
  workflowAllCandidateAppliedRolesFilter: [],
  workflowAllCandidateAppliedSkillsFilter: [],
  workflowAllCandidateAppliedAvailableHoursFilter: [],
  workflowAllCandidateTotalResults: null,
  workflowAllCandidateCurrentPage: 1,
  workflowAllCandidateResultsPerPage: 10,
  workflowAllCandidateTotalPages: null,
  workflowAllCandidatePageIds: [],
  workflowAllCandidateRolesFilter: [],
  workflowAllCandidateSkillsFilter: [],
  workflowAllCandidateAvailableHoursFilter: [],
  fetchingData: true,
  workflowAllCandidateAppliedRelExpFilterState: false,
  workflowAllCandidateAppliedTotalExpFilterState: false,
  workflowAllCandidateSearchAppliedFilters: [],

  workflowAllCandidateAppliedMinRelExpFilter: null,
  workflowAllCandidateAppliedMaxRelExpFilter: null,
  workflowAllCandidateAppliedMinTotalExpFilter: null,
  workflowAllCandidateAppliedMaxTotalExpFilter: null,
  workflowAllCandidateAppliedMatchingFilter: "0",
  workflowAllCandidateAppliedHasDoneFilter: [],
  workflowAllCandidateTableSelectedRows: [],

  candidatesListFilterFlexiSelected: [],
  candidatesListFilterActiveProjects: [],
  candidatesListApplaiedFilterFlexiSelected: [],
  candidatesListApplaiedFilterActiveProjects: [],
  workflowAllCandidateAppliedMatchingState: false,
  workflowAllCandidateAppliedMinMatching: null,
  workflowAllCandidateAppliedMaxMatching: null,
};

const workflowAllCandidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFLOW_ALL_CANDIDATE: {
      return {
        ...state,
        workflowAllCandidateList: action.payload.result.results,
        workflowAllCandidateTotalResults: action.payload.result.total_results,
        workflowAllCandidateTotalPages: action.payload.result.total_pages,
        workflowAllCandidatePageIds: action.payload.result.page_ids,
        fetchingData: false,
      };
    }
    case SEARCH_WORKFLOW_ALL_CANDIDATES_FILTERS:{
      return {
        ...state,
        workflowAllCandidateSearchAppliedFilters: action.payload,
      }
    }
    case RESET_WORKFLOW_SEARCH_ALL_CANDIDATES_FILTERS:{
      return {
        ...state,
        workflowAllCandidateSearchAppliedFilters: [],
      }
    }
    case GET_WORKFLOW_ALL_CANDIDATE_FILTERS: {
      return {
        ...state,
        workflowAllCandidateRolesFilter: action.payload.roles,
        workflowAllCandidateSkillsFilter: action.payload.skills,
        candidatesListFilterFlexiSelected: action.payload.flexibees_selected || [],
        candidatesListFilterActiveProjects: action.payload.active_projects || [],
        workflowAllCandidateAvailableHoursFilter: action.payload.total_available_hours,
      };
    }
    case CHANGE_CURRENT_WORKFLOW_ALL_CANDIDATE_PAGE: {
      return {
        ...state,
        workflowAllCandidateCurrentPage: action.payload,
      };
    }
    case VIEW_WORKFLOW_CANDIDATE_TIMELINE: {
      return {
        ...state,
        candidateTimeline: action.payload,
      };
    }
    case SET_WORKFLOW_ALL_CANDIDATE_ITEMS_PER_PAGE: {
      return {
        ...state,
        workflowAllCandidateResultsPerPage: action.payload,
      };
    }
    case SEARCH_WORKFLOW_ALL_CANDIDATE: {
      return {
        ...state,
        workflowAllCandidateSearchText: action.payload,
      };
    }

    case FILTER_WORKFLOW_ALL_CANDIDATE_ROLES: {
      return {
        ...state,
        workflowAllCandidateAppliedRolesFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_ALL_CANDIDATE_SKILLS: {
      return {
        ...state,
        workflowAllCandidateAppliedSkillsFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_ALL_CANDIDATE_AVAILABLE_HOURS: {
      return {
        ...state,
        workflowAllCandidateAppliedAvailableHoursFilter: action.payload,
      };
    }

    case WORKFLOW_ALL_CANDIDATE_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }

    case WORKFLOW_ALL_CANDIDATE_MIN_REL_EXP: {
      return {
        ...state,
        workflowAllCandidateAppliedMinRelExpFilter: action.payload,
      };
    }

    case WORKFLOW_ALL_CANDIDATE_MAX_REL_EXP: {
      return {
        ...state,
        workflowAllCandidateAppliedMaxRelExpFilter: action.payload,
      };
    }
    case WORKFLOW_ALL_CANDIDATE_FILTER_REL_EXP: {
      return {
        ...state,
        workflowAllCandidateAppliedRelExpFilterState: action.payload,
      };
    }

    case WORKFLOW_ALL_CANDIDATE_MIN_TOTAL_EXP: {
      return {
        ...state,
        workflowAllCandidateAppliedMinTotalExpFilter: action.payload,
      };
    }

    case WORKFLOW_ALL_CANDIDATE_MAX_TOTAL_EXP: {
      return {
        ...state,
        workflowAllCandidateAppliedMaxTotalExpFilter: action.payload,
      };
    }
    case WORKFLOW_ALL_CANDIDATE_FILTER_TOTAL_EXP: {
      return {
        ...state,
        workflowAllCandidateAppliedTotalExpFilterState: action.payload,
      };
    }

    case WORKFLOW_ALL_CANDIDATE_SET_TABLE_SELECTED_ROWS: {
      return {
        ...state,
        workflowAllCandidateTableSelectedRows: action.payload,
      };
    }
    case WORKFLOW_ALL_CANDIDATE_FILTER_MATCHING: {
      return {
        ...state,
        workflowAllCandidateAppliedMatchingState: action.payload,
      };
    }
    case WORKFLOW_ALL_CANDIDATE_MIN_MATCHING: {
      return {
        ...state,
        workflowAllCandidateAppliedMinMatching: action.payload,
      };
    }
    case WORKFLOW_ALL_CANDIDATE_MAX_MATCHING: {
      return {
        ...state,
        workflowAllCandidateAppliedMaxMatching: action.payload,
      };
    }
    case RESET_WORKFLOW_ALL_CANDIDATE: {
      return {
        ...state,
        fetchingData: true,
        workflowAllCandidateList: [],
        workflowAllCandidateCurrentPage: 1,
        workflowAllCandidateResultsPerPage: 10,
        workflowAllCandidateTotalPages: null,
        workflowAllCandidateSearchText: "",
        workflowAllCandidateTableSelectedRows: [],
        workflowAllCandidateAppliedRolesFilter: [],
        workflowAllCandidateAppliedAvailableHoursFilter: [],
        workflowAllCandidateAppliedSkillsFilter: [],
        workflowAllCandidateAppliedMinRelExpFilter: null,
        workflowAllCandidateAppliedMaxRelExpFilter: null,
        workflowAllCandidateAppliedRelExpFilterState: false,
        workflowAllCandidateAppliedMinTotalExpFilter: null,
        workflowAllCandidateAppliedMaxTotalExpFilter: null,
        workflowAllCandidateAppliedTotalExpFilterState: false,
        candidatesListApplaiedFilterFlexiSelected: [],
        candidatesListApplaiedFilterActiveProjects: [],
        workflowAllCandidateAppliedMatchingState: false,
        workflowAllCandidateAppliedMinMatching: null,
        workflowAllCandidateAppliedMaxMatching: null,
      };
    }
    case RESET_WORKFLOW_ALL_CANDIDATE_SEARCH_FILTERS: {
      return {
        ...state,
        workflowAllCandidateSearchAppliedFilters: [],
      };
    }
    case WORKFLOW_ALL_CANDIDATE_SET_HASDONE_FILTER: {
      return {
        ...state,
        workflowAllCandidateAppliedHasDoneFilter: action.payload,
      };
    }
    case WORKFLOW_ALL_CANDIDATE_SET_MATCHING_FILTER: {
      return {
        ...state,
        workflowAllCandidateAppliedMatchingFilter: action.payload,
      };
    }
    case FILTER_WORKFLOW_ALL_CANDIDATE_FLEXIBEES_SELECTED: {
      return {
        ...state,
        candidatesListApplaiedFilterFlexiSelected: action.payload,
      };
    }

    case FILTER_WORKFLOW_ALL_CANDIDATE_ACTIVE_PROJECTS: {
      return {
        ...state,
        candidatesListApplaiedFilterActiveProjects: action.payload,
      };
    }

    default:
      return state;
  }
};

export default workflowAllCandidateReducer;
