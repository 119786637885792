const BaseURL = `https://bed.flexibees.com/api/admin`;
const SocketURL = `wss://bed.flexibees.com/ws`;

export const API = {
  //
  LOGIN: `${BaseURL}/login/`,
  CHANGE_PASSWORD: `${BaseURL}/change-password/`,
  FORGOT_PASSWORD: `${BaseURL}/forgot-password/`,
  MEDIA_UPLOAD: `${BaseURL}/media-upload/`,
  VIEW_PROFILE: `${BaseURL}/view-profile/`,
  EDIT_PROFILE: `${BaseURL}/edit-profile/`,
  TAB_COUNT: `${BaseURL}/all-project-count/`,
  GET_FINAL: `${BaseURL}/final-selection`,
  GET_PROFILETABLE: `${BaseURL}/profile-table`,
  GET_PROFILETABLE_VIEW_PRICING: `${BaseURL}/view-pricing`,
  PROFILETABLE_UPDATE_PRICING: `${BaseURL}/add-pricing/`,
  GET_RECRUITMENT: `${BaseURL}/recruitment`,
  GET_RECRUITMENT_PRICING_HISTORY: `${BaseURL}/view-pricing`,
  VIEW_ASSIGN_PROJECT_INFO: `${BaseURL}/project-info`,
  VIEW_REOPEN_REASON: `${BaseURL}/view-reopen-reason`,
  VIEW_PROJECT: `${BaseURL}/view-project`,
  VIEW_PROJECT_INFO: `${BaseURL}/project-info`,
  UPDATE_ASSIGNMENT: `${BaseURL}/assignment-upload`,
  ASSIGN_SUSPEND_PROJECT: `${BaseURL}/suspend-project/`,
  GET_RECRUITERS: `${BaseURL}/recruiters`,
  ASSIGN_RECRUITER: `${BaseURL}/assign-recruiter`,
  GET_NOTIFICATION: `${BaseURL}/notification`,
  NOTIFICATION_COUNT: `${SocketURL}/admin-notification`,
  GET_CLOSED: `${BaseURL}/closed`,
  GET_SUSPENDED: `${BaseURL}/suspended`,
  VIEW_SUSPEND_REASON: `${BaseURL}/view-suspended-reason`,
  REOPEN_PROJECT: `${BaseURL}/reopen-project/`,
  GET_WORKFLOW_ALL_CANDIDATE: `${BaseURL}/all-candidates`,
  GET_WORKFLOW_CANDIDATES_FILTERS: `${BaseURL}/project-all-candidates-filters`,
  GET_WORKFLOW_SHORTLIST: `${BaseURL}/all-shortlist-candidates`,

  GET_CANDIDATES: `${BaseURL}/all-candidates-list`,
  GET_CANDIDATES_FILTERS: `${BaseURL}/all-candidates-filters/`,
  GET_CANDIDATE_DETAILS: `${BaseURL}/candidate-detail`,
  GET_WORKFLOW_INTEREST_CHECK: `${BaseURL}/interest-check-and-self-evaluation`,
  VIEW_SELF_ASSESMENT: `${BaseURL}/self-assessment`,

  GET_WORKFLOW_ASSIGNMENT: `${BaseURL}/assignment-candidates`,
  VIEW_FUNCTIONAL_FEEDBACK: `${BaseURL}/view-functional-feedback`,
  VIEW_ASSIGNMENT_FEEDBACK: `${BaseURL}/view-assignment-feedback`,

  GET_WORKFLOW_FLEXIFIT: `${BaseURL}/flexifit-candidates`,
  VIEW_FLEXIFIT_FEEDBACK: `${BaseURL}/view-flexifit-feedback`,
  VIEW_CLIENT_FEEDBACK: `${BaseURL}/view-client-feedback`,

  GET_WORKFLOW_FUNCTIONAL: `${BaseURL}/functional-candidates`,
  GET_WORKFLOW_FINAL: `${BaseURL}/final-candidates`,
  GET_CANDIDATES_CITY_LIST: `${BaseURL}/all-candidates-city-list`,

  GET_DYNAMIC_DROPDOWN_FROM_CONFIG: `${BaseURL}/dropdown-values/`,

  GET_CANDIDATE_TIMELINE: `${BaseURL}/candidate-timeline`,
  DOWNLOAD_CANDIDATE_TIMELINE: `${BaseURL}/candidate-timeline-download`,
  DOWNLOAD_FLEXIBEES_RESUME: `${BaseURL}/candidate-resume-download`,
  APP_INFO: `${BaseURL}/app-info/`,
  CANDIDATE_JOURNEY: `${BaseURL}/candidate-journey`,
  CANDIDATE_JOURNEY_FEEDBACK: `${BaseURL}/candidate-feedback-list`,
};
