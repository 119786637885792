import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosGetWithToken,
  axiosPut,
} from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { getDateFilter } from "@flexibees/core";

export const GET_RECRUITMENT = "GET_RECRUITMENT";
export const RECRUITMENT_LOADING = "RECRUITMENT_LOADING";
export const SET_RECRUITMENT_ITEMS_PER_PAGE = "SET_RECRUITMENT_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_RECRUITMENT_PAGE =
  "CHANGE_CURRENT_RECRUITMENT_PAGE";
export const SEARCH_RECRUITMENT = "SEARCH_RECRUITMENT";
export const FILTER_RECRUITMENT_FUNCTIONS = "FILTER_RECRUITMENT_FUNCTIONS";
export const FILTER_RECRUITMENT_ROLES = "FILTER_RECRUITMENT_ROLES";
export const PULL_DATA_GET_RECRUITMENT = "PULL_DATA_GET_RECRUITMENT";
export const FILTER_RECRUITMENT_STATUS = "FILTER_RECRUITMENT_STATUS";
export const FILTER_RECRUITMENT_RECRUITER = "FILTER_RECRUITMENT_RECRUITER";
export const FILTER_RECRUITMENT_BDMANAGER = "FILTER_RECRUITMENT_BDMANAGER";
export const FILTER_RECRUITMENT_DATE = "FILTER_RECRUITMENT_DATE";
export const FILTER_PROFILE_TABLE_DATE = "FILTER_PROFILE_TABLE_DATE";
export const FILTER_FINAL_PRICING_DATE = "FILTER_FINAL_PRICING_DATE";
export const FILTER_ASSIGNED_TO_RECRUITER_DATE = "FILTER_ASSIGNED_TO_RECRUITER_DATE";
export const SET_RECRUITMENT_PRICING_HISTORY =
  "SET_RECRUITMENT_PRICING_HISTORY";
export const VIEW_REOPEN_REASON = "VIEW_REOPEN_REASON";
export const FILTER_RECRUITMENT_RECRUITMENT_DAYS =
  "FILTER_RECRUITMENT_RECRUITMENT_DAYS";

export const getRecruitment = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().recruit.recruitmentResultsPerPage;
    const page = getstate().recruit.recruitmentCurrentPage;
    const seachText = getstate().recruit.recruitmentSearchText;
    const functions = getstate().recruit.recruitmentAppliedFunctionFilter;
    const roles = getstate().recruit.recruitmentAppliedRolesFilter;
    const status = getstate().recruit.recruitmentAppliedStatusFilter;
    const recruiters = getstate().recruit.recruitmentAppliedRecruiterFilter;
    const bdManager = getstate().recruit.recruitmentAppliedBDManagerFilter;
    const date = getDateFilter(
      getstate().recruit.recruitmentAppliedDateFilter[0]
    );
    const profileDate = getDateFilter(
      getstate().recruit.RecruitmentAppliedProfileDateFilter[0]
    );
    const finalPricingDate = getDateFilter(
      getstate().recruit.RecruitmentAppliedFinalPricingFilter[0]
    );
    const assignedToRecruitment = getDateFilter(
      getstate().recruit.RecruitmentAppliedAssignedToRecruiterFilter[0]
    );
    const no_of_days = getstate().recruit
      .recruitmentAppliedRecruitmentDaysFilter;

    const body = {
      search_term: seachText,
      filter_data: {
        functions: functions,
        roles: roles,
        status: status,
        recruiters: recruiters,
        start_date: date,
        date_sent_to_profile_table: profileDate,
        date_sent_final_client_pricing: finalPricingDate,
        date_assigned_to_recruiter: assignedToRecruitment,
        bd_managers: bdManager,
        no_of_days: no_of_days,
      },
    };

    axiosPostWithToken(
      `${API.GET_RECRUITMENT}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_RECRUITMENT,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch data.",
        });
      }
    });
  };
};

export const viewReOpenReason = (project_id, toggleModal, whichModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosGetWithToken(`${API.VIEW_REOPEN_REASON}/${project_id}/`, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_REOPEN_REASON,
            payload: res.data.data,
          });
          toggleModal(whichModal);
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              res.data.data.message ||
              "Something went wrong. Unable to fetch data.",
          });
        }
      }
    );
  };
};

export const fetchPricingHistory = (projectId, toggleModal, whichModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(
      `${API.GET_RECRUITMENT_PRICING_HISTORY}/${projectId}/`,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SET_RECRUITMENT_PRICING_HISTORY,
          payload: res.data.data.pricing,
        });
        toggleModal(whichModal);
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch pricing details.",
        });
      }
    });
  };
};

export const sendToProfileTable = (id, refreshCrm) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPut(`${API.SEND_TO_PROFILE_TABLE}/${id}/`, {}, token).then((res) => {
      if (res.data.status_code === 200) {
        refreshCrm();
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to process request.",
        });
      }
    });
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: SET_RECRUITMENT_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_RECRUITMENT_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: SEARCH_RECRUITMENT,
      payload: value,
    });
  };
};

export const filterFunctions = (functions) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_RECRUITMENT_FUNCTIONS,
      payload: functions,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_RECRUITMENT_ROLES,
      payload: roles,
    });
  };
};

export const filterStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_RECRUITMENT_STATUS,
      payload: status,
    });
  };
};

export const filterRecruiter = (recruiter) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_RECRUITMENT_RECRUITER,
      payload: recruiter,
    });
  };
};

export const filterBDManager = (bdManager) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_RECRUITMENT_BDMANAGER,
      payload: bdManager,
    });
  };
};

export const filterRecruitmentDays = (days) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_RECRUITMENT_RECRUITMENT_DAYS,
      payload: days,
    });
  };
};

export const filterStartDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_RECRUITMENT_DATE,
      payload: date,
    });
  };
};

export const filterSentToProfileDate = (profileDate) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_PROFILE_TABLE_DATE,
      payload: profileDate,
    });
  };
};

export const filterSentToFinalPricingDate = (finalPricingDate) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_FINAL_PRICING_DATE,
      payload: finalPricingDate,
    });
  };
};

export const filterAssignedToRecruiterDate = (assignedToRecruitment) => {
  return async (dispatch) => {
    dispatch({
      type: RECRUITMENT_LOADING,
    });
    dispatch({
      type: FILTER_ASSIGNED_TO_RECRUITER_DATE,
      payload: assignedToRecruitment,
    });
  };
};
