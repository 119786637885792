import {
  GET_RECRUITERS,
  RECRUITERS_LOADING,
  SET_RECRUITERS_ITEMS_PER_PAGE,
  CHANGE_CURRENT_RECRUITERS_PAGE,
  SEARCH_RECRUITERS,
  RECRUITERS_LEVEL_FILTER,
  RECRUITERS_SKILLS_FILTER,
  RECRUITERS_ACTIVE_PROJECTS_FILTER,
} from "../actions/recruitersAction";

const initialState = {
  recruitersList: [],
  recruitersPricingHistory: [],
  recruitersSearchText: "",
  recruitersTotalResults: null,
  recruitersCurrentPage: 1,
  recruitersResultsPerPage: 10,
  recruitersTotalPages: null,
  recruitersPageIds: [],
  fetchingData: true,
  recruiterSkillsFilter: [],
  recruiterActiveProjectsFilter: [],
  recruiterAppliedSkillsFilter: [],
  recruiterAppliedLevelFilter: [],
  recruitersActiveProjectsFilter: [],
  recruitersActiveApplaidProjectFilter: [],
};

const recruitersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECRUITERS: {
      return {
        ...state,
        recruitersList: action.payload.result.results,
        recruitersTotalResults: action.payload.result.total_results,
        recruitersTotalPages: action.payload.result.total_pages,
        recruitersPageIds: action.payload.result.page_ids,
        recruiterSkillsFilter: action.payload.filter_data.skills,
        recruitersActiveProjectsFilter:
          action.payload.filter_data.active_projects,
        fetchingData: false,
      };
    }

    case CHANGE_CURRENT_RECRUITERS_PAGE: {
      return {
        ...state,
        recruitersCurrentPage: action.payload,
      };
    }
    case SET_RECRUITERS_ITEMS_PER_PAGE: {
      return {
        ...state,
        recruitersResultsPerPage: action.payload,
      };
    }
    case SEARCH_RECRUITERS: {
      return {
        ...state,
        recruitersSearchText: action.payload,
      };
    }
    case RECRUITERS_LEVEL_FILTER: {
      return {
        ...state,
        recruiterAppliedLevelFilter: action.payload,
      };
    }
    case RECRUITERS_SKILLS_FILTER: {
      return {
        ...state,
        recruiterAppliedSkillsFilter: action.payload,
      };
    }
    case RECRUITERS_ACTIVE_PROJECTS_FILTER: {
      return {
        ...state,
        recruitersActiveApplaidProjectFilter: action.payload,
      };
    }
    case RECRUITERS_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    default:
      return state;
  }
};

export default recruitersReducer;
