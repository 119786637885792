import {
  GET_RECRUITMENT,
  RECRUITMENT_LOADING,
  SET_RECRUITMENT_ITEMS_PER_PAGE,
  CHANGE_CURRENT_RECRUITMENT_PAGE,
  SEARCH_RECRUITMENT,
  FILTER_RECRUITMENT_FUNCTIONS,
  FILTER_RECRUITMENT_ROLES,
  FILTER_RECRUITMENT_STATUS,
  FILTER_RECRUITMENT_RECRUITER,
  FILTER_RECRUITMENT_DATE,
  FILTER_PROFILE_TABLE_DATE,
  FILTER_FINAL_PRICING_DATE,
  FILTER_ASSIGNED_TO_RECRUITER_DATE,
  FILTER_RECRUITMENT_BDMANAGER,
  SET_RECRUITMENT_PRICING_HISTORY,
  VIEW_REOPEN_REASON,
  FILTER_RECRUITMENT_RECRUITMENT_DAYS,
} from "../actions/recruitmentAction";

const initialState = {
  recruitmentList: [],
  recruitmentPricingHistory: [],
  recruitmentSearchText: "",
  recruitmentAppliedFunctionFilter: [],
  recruitmentAppliedRolesFilter: [],
  recruitmentAppliedStatusFilter: [],
  recruitmentAppliedRecruiterFilter: [],
  recruitmentAppliedBDManagerFilter: [],
  recruitmentAppliedRecruitmentDaysFilter: [],
  recruitmentAppliedDateFilter: [],
  RecruitmentAppliedProfileDateFilter: [],
  RecruitmentAppliedFinalPricingFilter: [],
  RecruitmentAppliedAssignedToRecruiterFilter: [],
  recruitmentTotalResults: null,
  recruitmentCurrentPage: 1,
  recruitmentResultsPerPage: 10,
  recruitmentTotalPages: null,
  recruitmentPageIds: [],
  recruitmentFunctionFilter: [],
  recruitmentRolesFilter: [],
  recruitmentRecruiterFilter: [],
  recruitmentBDManagerFilter: [],
  recruitmentRecruitmentDaysFilter: [],
  recruitmentViewReopenReason: {},
  fetchingData: true,
};

const recruitmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECRUITMENT: {
      return {
        ...state,
        recruitmentList: action.payload.result.results,
        recruitmentTotalResults: action.payload.result.total_results,
        recruitmentTotalPages: action.payload.result.total_pages,
        recruitmentPageIds: action.payload.result.page_ids,
        recruitmentFunctionFilter: action.payload.filter_data.functions,
        recruitmentRolesFilter: action.payload.filter_data.roles,
        recruitmentRecruiterFilter: action.payload.filter_data.recruiters,
        recruitmentBDManagerFilter: action.payload.filter_data.bd_managers,
        recruitmentRecruitmentDaysFilter: action.payload.filter_data.no_of_days,
        fetchingData: false,
      };
    }
    case SET_RECRUITMENT_PRICING_HISTORY: {
      return {
        ...state,
        recruitmentPricingHistory: action.payload,
      };
    }
    case CHANGE_CURRENT_RECRUITMENT_PAGE: {
      return {
        ...state,
        recruitmentCurrentPage: action.payload,
      };
    }
    case SET_RECRUITMENT_ITEMS_PER_PAGE: {
      return {
        ...state,
        recruitmentResultsPerPage: action.payload,
      };
    }
    case SEARCH_RECRUITMENT: {
      return {
        ...state,
        recruitmentSearchText: action.payload,
      };
    }
    case FILTER_RECRUITMENT_FUNCTIONS: {
      return {
        ...state,
        recruitmentAppliedFunctionFilter: action.payload,
      };
    }
    case FILTER_RECRUITMENT_ROLES: {
      return {
        ...state,
        recruitmentAppliedRolesFilter: action.payload,
      };
    }
    case FILTER_RECRUITMENT_STATUS: {
      return {
        ...state,
        recruitmentAppliedStatusFilter: action.payload,
      };
    }
    case FILTER_RECRUITMENT_RECRUITER: {
      return {
        ...state,
        recruitmentAppliedRecruiterFilter: action.payload,
      };
    }
    case FILTER_RECRUITMENT_BDMANAGER: {
      return {
        ...state,
        recruitmentAppliedBDManagerFilter: action.payload,
      };
    }
    case FILTER_RECRUITMENT_DATE: {
      return {
        ...state,
        recruitmentAppliedDateFilter: action.payload,
      };
    }
    case FILTER_PROFILE_TABLE_DATE: {
      return {
        ...state,
        RecruitmentAppliedProfileDateFilter: action.payload,
      };
    }
    case FILTER_FINAL_PRICING_DATE: {
      return {
        ...state,
        RecruitmentAppliedFinalPricingFilter: action.payload,
      };
    }
    case FILTER_ASSIGNED_TO_RECRUITER_DATE: {
      return {
        ...state,
        RecruitmentAppliedAssignedToRecruiterFilter: action.payload,
      };
    }
    case FILTER_RECRUITMENT_RECRUITMENT_DAYS: {
      return {
        ...state,
        recruitmentAppliedRecruitmentDaysFilter: action.payload,
      };
    }
    case VIEW_REOPEN_REASON: {
      return {
        ...state,
        recruitmentViewReopenReason: action.payload,
      };
    }
    case RECRUITMENT_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    default:
      return state;
  }
};

export default recruitmentReducer;
