import {
  GET_WORKFLOW_FINAL,
  WORKFLOW_FINAL_LOADING,
  SET_WORKFLOW_FINAL_ITEMS_PER_PAGE,
  CHANGE_CURRENT_WORKFLOW_FINAL_PAGE,
  SEARCH_WORKFLOW_FINAL,
  FILTER_WORKFLOW_FINAL_ROLES,
  FILTER_WORKFLOW_FINAL_SKILLS,
  WORKFLOW_FINAL_MIN_REL_EXP,
  WORKFLOW_FINAL_MAX_REL_EXP,
  WORKFLOW_FINAL_FILTER_REL_EXP,
  WORKFLOW_FINAL_MIN_TOTAL_EXP,
  WORKFLOW_FINAL_MAX_TOTAL_EXP,
  WORKFLOW_FINAL_FILTER_TOTAL_EXP,
  WORKFLOW_FINAL_SET_TABLE_SELECTED_ROWS,
  RESET_WORKFLOW_FINAL,
  FILTER_WORKFLOW_FINAL_STATUS,
  WORKFLOW_FINAL_SET_HASDONE_FILTER,
  WORKFLOW_FINAL_SET_MATCHING_FILTER,
  WORKFLOW_FINAL_VIEW_CLIENT_FEEDBACK,
  WORKFLOW_FINAL_VIEW_FINAL_FEEDBACK,
  WORKFLOW_FINAL_VIEW_FLEXIFIT_FEEDBACK,
  WORKFLOW_FINAL_AVAILABLE_HOURS_FILTER,
  VIEW_WORKFLOW_CANDIDATE_TIMELINE,
  FILTER_WORKFLOW_FINAL_ACTIVE_PROJECTS,
  FILTER_WORKFLOW_FINAL_FLEXIBEES_SELECTED,
} from "../actions/workflowFinalAction";

const initialState = {
  workflowFinalList: [],
  workflowFinalPricingHistory: [],
  workflowFinalSearchText: "",
  workflowFinalAppliedRolesFilter: [],
  workflowFinalAppliedSkillsFilter: [],
  workflowFinalAppliedAvailableHoursFilter: [],
  workflowFinalAppliedStatusFilter: [],
  workflowFinalTotalResults: null,
  workflowFinalCurrentPage: 1,
  workflowFinalResultsPerPage: 10,
  workflowFinalTotalPages: null,
  workflowFinalPageIds: [],
  workflowFinalRolesFilter: [],
  workflowFinalSkillsFilter: [],
  workflowFinalAvailableHoursFilter: [],
  fetchingData: true,
  workflowFinalAppliedRelExpFilterState: false,

  workflowFinalAppliedMinRelExpFilter: null,
  workflowFinalAppliedMaxRelExpFilter: null,
  workflowFinalAppliedMinTotalExpFilter: null,
  workflowFinalAppliedMaxTotalExpFilter: null,

  workflowFinalTableSelectedRows: [],
  workflowFinalAppliedMatchingFilter: "0",
  workflowFinalAppliedHasDoneFilter: [],

  workflowClientDetails: {},
  workflowFinalInterviewDetails: {},
  workflowFinalFlexifitInterviewDetails: {},

  candidatesListFilterFlexiSelected: [],
  candidatesListFilterActiveProjects: [],
  candidatesListApplaiedFilterFlexiSelected: [],
  candidatesListApplaiedFilterActiveProjects: [],
};

const workflowFinalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFLOW_FINAL: {
      return {
        ...state,
        workflowFinalList: action.payload.result.results,
        workflowFinalTotalResults: action.payload.result.total_results,
        workflowFinalTotalPages: action.payload.result.total_pages,
        workflowFinalPageIds: action.payload.result.page_ids,
        workflowFinalRolesFilter: action.payload.filter_data.roles,
        workflowFinalSkillsFilter: action.payload.filter_data.skills,
        workflowFinalAvailableHoursFilter:
          action.payload.filter_data.total_available_hours,
        candidatesListFilterFlexiSelected:
          action.payload.filter_data.flexibees_selected || [],
        candidatesListFilterActiveProjects:
          action.payload.filter_data.active_projects || [],
        fetchingData: false,
      };
    }
    case VIEW_WORKFLOW_CANDIDATE_TIMELINE: {
      return {
        ...state,
        candidateTimeline: action.payload,
      };
    }
    case CHANGE_CURRENT_WORKFLOW_FINAL_PAGE: {
      return {
        ...state,
        workflowFinalCurrentPage: action.payload,
      };
    }
    case SET_WORKFLOW_FINAL_ITEMS_PER_PAGE: {
      return {
        ...state,
        workflowFinalResultsPerPage: action.payload,
      };
    }
    case SEARCH_WORKFLOW_FINAL: {
      return {
        ...state,
        workflowFinalSearchText: action.payload,
      };
    }

    case FILTER_WORKFLOW_FINAL_ROLES: {
      return {
        ...state,
        workflowFinalAppliedRolesFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_FINAL_SKILLS: {
      return {
        ...state,
        workflowFinalAppliedSkillsFilter: action.payload,
      };
    }

    case WORKFLOW_FINAL_AVAILABLE_HOURS_FILTER: {
      return {
        ...state,
        workflowFinalAppliedAvailableHoursFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_FINAL_STATUS: {
      return {
        ...state,
        workflowFinalAppliedStatusFilter: action.payload,
      };
    }

    case WORKFLOW_FINAL_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }

    case WORKFLOW_FINAL_MIN_REL_EXP: {
      return {
        ...state,
        workflowFinalAppliedMinRelExpFilter: action.payload,
      };
    }

    case WORKFLOW_FINAL_MAX_REL_EXP: {
      return {
        ...state,
        workflowFinalAppliedMaxRelExpFilter: action.payload,
      };
    }
    case WORKFLOW_FINAL_FILTER_REL_EXP: {
      return {
        ...state,
        workflowFinalAppliedRelExpFilterState: action.payload,
      };
    }
    case WORKFLOW_FINAL_MIN_TOTAL_EXP: {
      return {
        ...state,
        workflowFinalAppliedMinTotalExpFilter: action.payload,
      };
    }

    case WORKFLOW_FINAL_MAX_TOTAL_EXP: {
      return {
        ...state,
        workflowFinalAppliedMaxTotalExpFilter: action.payload,
      };
    }
    case WORKFLOW_FINAL_FILTER_TOTAL_EXP: {
      return {
        ...state,
        workflowFinalAppliedTotalExpFilterState: action.payload,
      };
    }
    case WORKFLOW_FINAL_SET_TABLE_SELECTED_ROWS: {
      return {
        ...state,
        workflowFinalTableSelectedRows: action.payload,
      };
    }
    case RESET_WORKFLOW_FINAL: {
      return {
        ...state,
        fetchingData: true,
        workflowFinalList: [],
        workflowFinalCurrentPage: 1,
        workflowFinalResultsPerPage: 10,
        workflowFinalTotalPages: null,
        workflowFinalSearchText: "",
        workflowFinalTableSelectedRows: [],
        workflowFinalAppliedRolesFilter: [],
        workflowFinalAppliedSkillsFilter: [],
        workflowFinalAppliedStatusFilter: [],
        workflowFinalAppliedAvailableHoursFilter: [],
        workflowFinalAppliedRelExpFilterState: false,
        workflowFinalAppliedMinRelExpFilter: null,
        workflowFinalAppliedMaxRelExpFilter: null,
        workflowFinalAppliedMinTotalExpFilter: null,
        workflowFinalAppliedMaxTotalExpFilter: null,
        workflowFinalAppliedMatchingFilter: "0",
        workflowFinalAppliedHasDoneFilter: [],
        candidatesListApplaiedFilterFlexiSelected: [],
        candidatesListApplaiedFilterActiveProjects: [],
      };
    }
    case WORKFLOW_FINAL_SET_HASDONE_FILTER: {
      return {
        ...state,
        workflowFinalAppliedHasDoneFilter: action.payload,
      };
    }
    case WORKFLOW_FINAL_SET_MATCHING_FILTER: {
      return {
        ...state,
        workflowFinalAppliedMatchingFilter: action.payload,
      };
    }
    case WORKFLOW_FINAL_VIEW_CLIENT_FEEDBACK: {
      return {
        ...state,
        workflowClientDetails: action.payload,
      };
    }
    case WORKFLOW_FINAL_VIEW_FINAL_FEEDBACK: {
      return {
        ...state,
        workflowFinalInterviewDetails: action.payload,
      };
    }
    case WORKFLOW_FINAL_VIEW_FLEXIFIT_FEEDBACK: {
      return {
        ...state,
        workflowFinalFlexifitInterviewDetails: action.payload,
      };
    }
    case FILTER_WORKFLOW_FINAL_FLEXIBEES_SELECTED: {
      return {
        ...state,
        candidatesListApplaiedFilterFlexiSelected: action.payload,
      };
    }

    case FILTER_WORKFLOW_FINAL_ACTIVE_PROJECTS: {
      return {
        ...state,
        candidatesListApplaiedFilterActiveProjects: action.payload,
      };
    }
    default:
      return state;
  }
};

export default workflowFinalReducer;
