import React from "react";
import { useDispatch } from "react-redux";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import { FbSidebarItem } from "@flexibees/core";
import { Link, useRouteMatch } from "react-router-dom";
import * as workflowAllCandidateAction from "../actions/workflowAllCandidateAction";
import * as workflowShortlistAction from "../actions/workflowShortlistAction";
import * as workflowInterestCheckAction from "../actions/workflowInterestCheckAction";
import * as workflowAssignmentAction from "../actions/workflowAssignmentAction";
import * as workflowFunctionalAction from "../actions/workflowFunctionalAction";
import * as workflowFlexifitAction from "../actions/workflowFlexifitAction";
import * as workflowFinalAction from "../actions/workflowFinalAction";

const menuItems = [
  {
    name: "Dashboard",
    icon: HomeOutlinedIcon,
    path: "/dashboard",
  },
  {
    name: "All Projects",
    icon: ListOutlinedIcon,
    path: "/projects",
  },
  {
    name: "All Candidates",
    icon: PeopleAltOutlinedIcon,
    path: "/candidates",
  },
];

export default function MenuItems() {
  const dispatch = useDispatch();
  const [activeMenuIndex, setactiveMenuIndex] = React.useState(
    menuItems.findIndex((mItem) => window.location.href.includes(mItem.path))
  );
  const [activeMenu, setActiveMenu] = React.useState(
    activeMenuIndex < 0 ? "" : menuItems[activeMenuIndex].name
  );

  React.useEffect(() => {
    setactiveMenuIndex(
      menuItems.findIndex((mItem) => window.location.href.includes(mItem.path))
    );
  }, [window.location.href]);

  React.useEffect(() => {
    setActiveMenu(activeMenuIndex < 0 ? "" : menuItems[activeMenuIndex].name);
  }, [activeMenuIndex]);

  const changeActiveMenu = (menu) => {
    setActiveMenu(menu);
    dispatch(workflowAllCandidateAction.resetTableData());
    dispatch(workflowShortlistAction.resetTableData());
    dispatch(workflowInterestCheckAction.resetTableData());
    dispatch(workflowAssignmentAction.resetTableData());
    dispatch(workflowFunctionalAction.resetTableData());
    dispatch(workflowFlexifitAction.resetTableData());
    dispatch(workflowFinalAction.resetTableData());
    dispatch(workflowAllCandidateAction.resetSearchData());
  };

  const { path } = useRouteMatch();

  return (
    <div>
      {menuItems.map((item, index) => {
        return (
          <Link to={`${path}${item.path}`} style={{ textDecoration: "none" }}>
            <FbSidebarItem
              name={item.name}
              icon={item.icon}
              key={index}
              activeMenu={activeMenu}
              changeActiveMenu={changeActiveMenu}
            />
          </Link>
        );
      })}
    </div>
  );
}
