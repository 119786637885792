import {
  GET_WORKFLOW_INTEREST_CHECK,
  WORKFLOW_INTEREST_CHECK_LOADING,
  SET_WORKFLOW_INTEREST_CHECK_ITEMS_PER_PAGE,
  CHANGE_CURRENT_WORKFLOW_INTEREST_CHECK_PAGE,
  SEARCH_WORKFLOW_INTEREST_CHECK,
  FILTER_WORKFLOW_INTEREST_CHECK_ROLES,
  FILTER_WORKFLOW_INTEREST_CHECK_SKILLS,
  WORKFLOW_INTEREST_CHECK_SET_TABLE_SELECTED_ROWS,
  RESET_WORKFLOW_INTEREST_CHECK,
  FILTER_WORKFLOW_INTEREST_CHECK_STATUS,
  WORKFLOW_INTEREST_CHECK_VIEW_SELF_ASSESMENT,
  FILTER_WORKFLOW_INTEREST_CHECK_AVAILABLE_HOURS,
  VIEW_WORKFLOW_CANDIDATE_TIMELINE,
  FILTER_WORKFLOW_INTEREST_CHECK_ACTIVE_PROJECTS,
  FILTER_WORKFLOW_INTEREST_CHECK_FLEXIBEES_SELECTED,
} from "../actions/workflowInterestCheckAction";

const initialState = {
  workflowInterestCheckList: [],
  workflowSelfAssesment: [],
  workflowInterestCheckPricingHistory: [],
  workflowInterestCheckSearchText: "",
  workflowInterestCheckAppliedRolesFilter: [],
  workflowInterestCheckAppliedSkillsFilter: [],
  workflowInterestCheckAppliedStatusFilter: [],
  workflowInterestCheckAppliedAvailableHoursFilter: [],
  workflowInterestCheckTotalResults: null,
  workflowInterestCheckCurrentPage: 1,
  workflowInterestCheckResultsPerPage: 10,
  workflowInterestCheckTotalPages: null,
  workflowInterestCheckPageIds: [],
  workflowInterestCheckRolesFilter: [],
  workflowInterestCheckSkillsFilter: [],
  workflowInterestCheckAvailableHoursFilter: [],
  fetchingData: true,

  workflowInterestCheckTableSelectedRows: [],
  workflowInterestCheckAppliedMatchingFilter: "0",
  workflowInterestCheckAppliedHasDoneFilter: [],

  candidatesListFilterFlexiSelected: [],
  candidatesListFilterActiveProjects: [],
  candidatesListApplaiedFilterFlexiSelected: [],
  candidatesListApplaiedFilterActiveProjects: [],
};

const workflowInterestCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFLOW_INTEREST_CHECK: {
      return {
        ...state,
        workflowInterestCheckList: action.payload.result.results,
        workflowInterestCheckTotalResults: action.payload.result.total_results,
        workflowInterestCheckTotalPages: action.payload.result.total_pages,
        workflowInterestCheckPageIds: action.payload.result.page_ids,
        workflowInterestCheckRolesFilter: action.payload.filter_data.roles,
        workflowInterestCheckSkillsFilter: action.payload.filter_data.skills,
        workflowInterestCheckAvailableHoursFilter:
          action.payload.filter_data.total_available_hours,
        candidatesListFilterFlexiSelected:
          action.payload.filter_data.flexibees_selected || [],
        candidatesListFilterActiveProjects:
          action.payload.filter_data.active_projects || [],
        fetchingData: false,
      };
    }
    case CHANGE_CURRENT_WORKFLOW_INTEREST_CHECK_PAGE: {
      return {
        ...state,
        workflowInterestCheckCurrentPage: action.payload,
      };
    }
    case SET_WORKFLOW_INTEREST_CHECK_ITEMS_PER_PAGE: {
      return {
        ...state,
        workflowInterestCheckResultsPerPage: action.payload,
      };
    }
    case VIEW_WORKFLOW_CANDIDATE_TIMELINE: {
      return {
        ...state,
        candidateTimeline: action.payload,
      };
    }
    case SEARCH_WORKFLOW_INTEREST_CHECK: {
      return {
        ...state,
        workflowInterestCheckSearchText: action.payload,
      };
    }

    case FILTER_WORKFLOW_INTEREST_CHECK_ROLES: {
      return {
        ...state,
        workflowInterestCheckAppliedRolesFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_INTEREST_CHECK_SKILLS: {
      return {
        ...state,
        workflowInterestCheckAppliedSkillsFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_INTEREST_CHECK_AVAILABLE_HOURS: {
      return {
        ...state,
        workflowInterestCheckAppliedAvailableHoursFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_INTEREST_CHECK_STATUS: {
      return {
        ...state,
        workflowInterestCheckAppliedStatusFilter: action.payload,
      };
    }

    case WORKFLOW_INTEREST_CHECK_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }

    case WORKFLOW_INTEREST_CHECK_SET_TABLE_SELECTED_ROWS: {
      return {
        ...state,
        workflowInterestCheckTableSelectedRows: action.payload,
      };
    }
    case RESET_WORKFLOW_INTEREST_CHECK: {
      return {
        ...state,
        fetchingData: true,
        workflowInterestCheckList: [],
        workflowInterestCheckCurrentPage: 1,
        workflowInterestCheckResultsPerPage: 10,
        workflowInterestCheckTotalPages: null,
        workflowInterestCheckSearchText: "",
        workflowInterestCheckTableSelectedRows: [],
        workflowInterestCheckAppliedRolesFilter: [],
        workflowInterestCheckAppliedSkillsFilter: [],
        workflowInterestCheckAppliedStatusFilter: [],
        workflowInterestCheckAppliedAvailableHoursFilter: [],
        workflowInterestCheckAppliedRelExpFilterState: false,
        workflowInterestCheckAppliedMinRelExpFilter: null,
        workflowInterestCheckAppliedMaxRelExpFilter: null,
        workflowInterestCheckAppliedMinTotalExpFilter: null,
        workflowInterestCheckAppliedMaxTotalExpFilter: null,
        workflowInterestCheckAppliedMatchingFilter: "0",
        workflowInterestCheckAppliedHasDoneFilter: [],
        candidatesListApplaiedFilterFlexiSelected: [],
        candidatesListApplaiedFilterActiveProjects: [],
      };
    }

    case WORKFLOW_INTEREST_CHECK_VIEW_SELF_ASSESMENT: {
      return {
        ...state,
        workflowSelfAssesment: action.payload,
      };
    }
    case FILTER_WORKFLOW_INTEREST_CHECK_FLEXIBEES_SELECTED: {
      return {
        ...state,
        candidatesListApplaiedFilterFlexiSelected: action.payload,
      };
    }

    case FILTER_WORKFLOW_INTEREST_CHECK_ACTIVE_PROJECTS: {
      return {
        ...state,
        candidatesListApplaiedFilterActiveProjects: action.payload,
      };
    }
    default:
      return state;
  }
};

export default workflowInterestCheckReducer;
