import { API } from "../constants/api";
import { axiosGetWithToken, axiosPostWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { CHANGE_VIEW_PROJECT_TAB } from "./viewProjectTabAction";
import axios from "axios";

export const GET_WORKFLOW_ALL_CANDIDATE = "GET_WORKFLOW_ALL_CANDIDATE";
export const GET_WORKFLOW_ALL_CANDIDATE_FILTERS = "GET_WORKFLOW_ALL_CANDIDATE_FILTERS";
export const WORKFLOW_ALL_CANDIDATE_LOADING = "WORKFLOW_ALL_CANDIDATE_LOADING";
export const SET_WORKFLOW_ALL_CANDIDATE_ITEMS_PER_PAGE =
  "SET_WORKFLOW_ALL_CANDIDATE_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_WORKFLOW_ALL_CANDIDATE_PAGE =
  "CHANGE_CURRENT_WORKFLOW_ALL_CANDIDATE_PAGE";
export const SEARCH_WORKFLOW_ALL_CANDIDATE = "SEARCH_WORKFLOW_ALL_CANDIDATE";
export const SEARCH_WORKFLOW_ALL_CANDIDATES_FILTERS = "SEARCH_WORKFLOW_ALL_CANDIDATES_FILTERS";
export const RESET_WORKFLOW_SEARCH_ALL_CANDIDATES_FILTERS = "RESET_WORKFLOW_SEARCH_ALL_CANDIDATES_FILTERS";
export const FILTER_WORKFLOW_ALL_CANDIDATE_ROLES =
  "FILTER_WORKFLOW_ALL_CANDIDATE_ROLES";
export const FILTER_WORKFLOW_ALL_CANDIDATE_SKILLS =
  "FILTER_WORKFLOW_ALL_CANDIDATE_SKILLS";
export const WORKFLOW_ALL_CANDIDATE_MIN_REL_EXP =
  "WORKFLOW_ALL_CANDIDATE_MIN_REL_EXP";
export const WORKFLOW_ALL_CANDIDATE_MAX_REL_EXP =
  "WORKFLOW_ALL_CANDIDATE_MAX_REL_EXP";
export const WORKFLOW_ALL_CANDIDATE_FILTER_REL_EXP =
  "WORKFLOW_ALL_CANDIDATE_FILTER_REL_EXP";
export const WORKFLOW_ALL_CANDIDATE_SET_TABLE_SELECTED_ROWS =
  "WORKFLOW_ALL_CANDIDATE_SET_TABLE_SELECTED_ROWS";
export const RESET_WORKFLOW_ALL_CANDIDATE = "RESET_WORKFLOW_ALL_CANDIDATE";
export const WORKFLOW_ALL_CANDIDATE_SET_HASDONE_FILTER =
  "WORKFLOW_ALL_CANDIDATE_SET_HASDONE_FILTER";
export const WORKFLOW_ALL_CANDIDATE_SET_MATCHING_FILTER =
  "WORKFLOW_ALL_CANDIDATE_SET_MATCHING_FILTER";
export const WORKFLOW_ALL_CANDIDATE_MIN_TOTAL_EXP =
  "WORKFLOW_ALL_CANDIDATE_MIN_TOTAL_EXP";
export const WORKFLOW_ALL_CANDIDATE_MAX_TOTAL_EXP =
  "WORKFLOW_ALL_CANDIDATE_MAX_TOTAL_EXP";
export const WORKFLOW_ALL_CANDIDATE_FILTER_TOTAL_EXP =
  "WORKFLOW_ALL_CANDIDATE_FILTER_TOTAL_EXP";
export const FILTER_WORKFLOW_ALL_CANDIDATE_AVAILABLE_HOURS =
  "FILTER_WORKFLOW_ALL_CANDIDATE_AVAILABLE_HOURS";
export const VIEW_WORKFLOW_CANDIDATE_TIMELINE =
  "VIEW_WORKFLOW_CANDIDATE_TIMELINE";
export const FILTER_WORKFLOW_ALL_CANDIDATE_ACTIVE_PROJECTS =
  "FILTER_WORKFLOW_ALL_CANDIDATE_ACTIVE_PROJECTS";
export const FILTER_WORKFLOW_ALL_CANDIDATE_FLEXIBEES_SELECTED =
  "FILTER_WORKFLOW_ALL_CANDIDATE_FLEXIBEES_SELECTED";
export const RESET_WORKFLOW_ALL_CANDIDATE_SEARCH_FILTERS =
  "RESET_WORKFLOW_ALL_CANDIDATE_SEARCH_FILTERS";
export const WORKFLOW_ALL_CANDIDATE_FILTER_MATCHING =
  "WORKFLOW_ALL_CANDIDATE_FILTER_MATCHING";
export const WORKFLOW_ALL_CANDIDATE_MIN_MATCHING =
  "WORKFLOW_ALL_CANDIDATE_MIN_MATCHING";
export const WORKFLOW_ALL_CANDIDATE_MAX_MATCHING =
  "WORKFLOW_ALL_CANDIDATE_MAX_MATCHING";

export const getCandidateTimeline = (id, toggleModal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    axiosGetWithToken(`${API.GET_CANDIDATE_TIMELINE}/${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_WORKFLOW_CANDIDATE_TIMELINE,
            payload: res.data.data,
          });
          toggleModal();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};
export const downloadCandidateTimeline = (id, endDownload) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    var config = {
      headers: { Authorization: token, "Content-Type": "application/pdf" },
      responseType: "blob",
    };

    axios
      .get(`${API.DOWNLOAD_CANDIDATE_TIMELINE}/${id}/`, config)
      .then((response) => {
        endDownload();
        let url = window.URL.createObjectURL(response.data);
        window.open(url);
      });
  };
};
export const getAllCandidates = (projectId) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize =
      getstate().workflowCandidate.workflowAllCandidateResultsPerPage;
    const page = getstate().workflowCandidate.workflowAllCandidateCurrentPage;
    const seachText =
      getstate().workflowCandidate.workflowAllCandidateSearchText;
    const roles =
      getstate().workflowCandidate.workflowAllCandidateAppliedRolesFilter;
    const skills =
      getstate().workflowCandidate.workflowAllCandidateAppliedSkillsFilter;
    const minRelExp =
      getstate().workflowCandidate.workflowAllCandidateAppliedMinRelExpFilter;
    const maxRelExp =
      getstate().workflowCandidate.workflowAllCandidateAppliedMaxRelExpFilter;
    const minTotalExp =
      getstate().workflowCandidate.workflowAllCandidateAppliedMinTotalExpFilter;
    const maxTotalExp =
      getstate().workflowCandidate.workflowAllCandidateAppliedMaxTotalExpFilter;
    const total_available_hours =
      getstate().workflowCandidate
        .workflowAllCandidateAppliedAvailableHoursFilter;
    const flexibees_selected =
      getstate().workflowCandidate.candidatesListApplaiedFilterFlexiSelected;
    const active_projects =
      getstate().workflowCandidate.candidatesListApplaiedFilterActiveProjects;

    const matching =
      getstate().workflowCandidate.workflowAllCandidateAppliedMatchingFilter ===
      "0"
        ? false
        : true;
    const search_filters = 
      getstate().workflowCandidate.workflowAllCandidateSearchAppliedFilters;
    const minMacthing = 
      getstate().workflowCandidate.workflowAllCandidateAppliedMinMatching;
    const maxMacthing =
      getstate().workflowCandidate.workflowAllCandidateAppliedMaxMatching
    const minValue = minMacthing === '' ? null : parseInt(minMacthing)
    const maxValue = maxMacthing === '' ? null : parseInt(maxMacthing) 

    const body = {
      search_term: seachText,
      filter_data: {
        roles: roles,
        skills: skills,
        matching: matching,
        flexibees_selected: flexibees_selected,
        active_projects: active_projects,
        relevant_experience: {
          min: minRelExp,
          max: maxRelExp,
        },
        years_of_experience: {
          min: minTotalExp,
          max: maxTotalExp,
        },
        match: {
          min: minValue,
          max: maxValue,
        },
        total_available_hours: total_available_hours,
      },
      search_fields: search_filters,
    };

    axiosPostWithToken(
      `${API.GET_WORKFLOW_ALL_CANDIDATE}/${projectId}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_WORKFLOW_ALL_CANDIDATE,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to fetch data.",
        });
      }
    });
  };
};

export const getAllCandidatesFilters = (projectId) => {
  return async (dispatch, getState) => {
  
    const token = getState().auth.token;

    axiosGetWithToken(`${API.GET_WORKFLOW_CANDIDATES_FILTERS}/${projectId}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_WORKFLOW_ALL_CANDIDATE_FILTERS,
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data),
        });
      });
  };
};

export const resetTableData = () => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_VIEW_PROJECT_TAB,
      payload: 0,
    });
    dispatch({
      type: RESET_WORKFLOW_ALL_CANDIDATE,
    });
  };
};

export const resetWorkflowAllCandidateSearchFilters = () => {
  return {
    type: RESET_WORKFLOW_ALL_CANDIDATE_SEARCH_FILTERS,
  };
};

export const addToShortlist = (body, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(`${API.ADD_TO_SHORTLIST}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        refresh();
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to process request.",
        });
      }
    });
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: SET_WORKFLOW_ALL_CANDIDATE_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_WORKFLOW_ALL_CANDIDATE_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: SEARCH_WORKFLOW_ALL_CANDIDATE,
      payload: value,
    });
  };
};

export const searchFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SEARCH_WORKFLOW_ALL_CANDIDATES_FILTERS,
      payload: value,
    });
  };
};

export const resetSearchData = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_WORKFLOW_SEARCH_ALL_CANDIDATES_FILTERS,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_ALL_CANDIDATE_ROLES,
      payload: roles,
    });
  };
};

export const filterSkills = (skills) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_ALL_CANDIDATE_SKILLS,
      payload: skills,
    });
  };
};

export const filterAvailableHours = (hours) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_ALL_CANDIDATE_AVAILABLE_HOURS,
      payload: hours,
    });
  };
};

export const changeMinRelExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MIN_REL_EXP,
      payload: value,
    });
  };
};

export const changeMaxRelExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MAX_REL_EXP,
      payload: value,
    });
  };
};

export const clearRelExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MIN_REL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MAX_REL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_FILTER_REL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterRelExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_FILTER_REL_EXP,
      payload: true,
    });
    filterData();
  };
};

export const changeMinTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MIN_TOTAL_EXP,
      payload: value,
    });
  };
};

export const changeMaxTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MAX_TOTAL_EXP,
      payload: value,
    });
  };
};

export const clearTotalExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MIN_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MAX_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_FILTER_TOTAL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterTotalExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_FILTER_TOTAL_EXP,
      payload: true,
    });
    filterData();
  };
};

export const setTableRows = (rows) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_SET_TABLE_SELECTED_ROWS,
      payload: rows,
    });
  };
};

export const setHasDoneFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_SET_HASDONE_FILTER,
      payload: data,
    });
  };
};

export const setMatchingFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_SET_MATCHING_FILTER,
      payload: data,
    });
  };
};

export const setFlexibeesSelectedFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_ALL_CANDIDATE_FLEXIBEES_SELECTED,
      payload: value,
    });
  };
};

export const setActiveProjectsFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_ALL_CANDIDATE_ACTIVE_PROJECTS,
      payload: value,
    });
  };
};
export const changeMinMatching = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MIN_MATCHING,
      payload: value,
    });
  };
};

export const changeMaxMatching = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MAX_MATCHING,
      payload: value,
    });
  };
};
export const clearMatchingFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MIN_MATCHING,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_MAX_MATCHING,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_FILTER_MATCHING,
      payload: false,
    });
    filterData();
  };
};

export const filterMatching = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_LOADING,
    });
    dispatch({
      type: WORKFLOW_ALL_CANDIDATE_FILTER_MATCHING,
      payload: true,
    });
    filterData();
  };
};
