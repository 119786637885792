import React from "react";
import { FbTypeography, FbBreadcrumbs, FbmakeStyles } from "@flexibees/core";
import { withRouter } from "react-router-dom";

const useStyles = FbmakeStyles({
  root: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    color: "#000000",
    textTransform: "capitalize",
  },
  lastItem: {
    fontSize: "13px",
    textTransform: "capitalize",
  },
});
const BreadCrumbs = (props) => {
  const { location } = props;
  const classes = useStyles();
  const { pathname } = location;
  const pathNames = pathname.split("/").filter((item) => item);

  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  const handleRoute = (route) => {
    if (
      route === "update" ||
      route === "view" ||
      route === "assign" ||
      route === "detail" ||
      route === "details"
    ) {
      return;
    }
    props.history.push(`/admin/${route}`);
  };

  return (
    <FbBreadcrumbs separator=">">
      {pathNames.map((item, index) => {
        const lastItem = index === pathNames.length - 1;
        if (hasNumber(item) || item === "admin") {
          return;
        }
        return lastItem ? (
          <FbTypeography key={item} className={classes.lastItem}>
            {item === "projects"
              ? "All Projects"
              : item === "update"
              ? "Update Project Details"
              : item === "assign"
              ? "Assign Recruiter"
              : item === "view"
              ? "Recruitment Workflow"
              : item === "detail"
              ? "Project Details"
              : item === "details"
              ? "Candidate Details"
              : item === "candidates"
              ? "All Candidates"
              : item}
          </FbTypeography>
        ) : (
          <FbTypeography
            onClick={() => handleRoute(item)}
            key={item}
            className={classes.root}
          >
            {item === "projects"
              ? "All Projects"
              : item === "update"
              ? "Update Project Details"
              : item === "assign"
              ? "Assign Recruiter"
              : item === "view"
              ? "Recruitment Workflow"
              : item === "detail"
              ? "Project Details"
              : item === "details"
              ? "Candidate Details"
              : item === "candidates"
              ? "All Candidates"
              : item}
          </FbTypeography>
        );
      })}
    </FbBreadcrumbs>
  );
};

export default withRouter(BreadCrumbs);
