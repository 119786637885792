export const MAX_STACK_COUNT = 3;

export const DateFilter = [
  {
    id: 1,
    tag_name: "Today",
  },
  {
    id: 2,
    tag_name: "Yesterday",
  },
  {
    id: 3,
    tag_name: "This Week",
  },
  {
    id: 4,
    tag_name: "This Month",
  },
  {
    id: 5,
    tag_name: "This Year",
  },
];

export const getRecruitmentStatusLabel = (value) => {
  if (value === 7) {
    return "New";
  } else if (value === 8) {
    return "In Progress";
  } else {
    return "Reopen";
  }
};
