import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosGetWithToken,
  axiosPut,
} from "../services/apiServices";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";
import { getDateFilter, formatDate } from "@flexibees/core";

export const GET_CLOSED = "GET_CLOSED";
export const CLOSED_LOADING = "CLOSED_LOADING";
export const SET_CLOSED_ITEMS_PER_PAGE = "SET_CLOSED_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_CLOSED_PAGE = "CHANGE_CURRENT_CLOSED_PAGE";
export const SEARCH_CLOSED = "SEARCH_CLOSED";
export const FILTER_CLOSED_FUNCTIONS = "FILTER_CLOSED_FUNCTIONS";
export const FILTER_CLOSED_ROLES = "FILTER_CLOSED_ROLES";
export const FILTER_CLOSED_RECRUITER = "FILTER_CLOSED_RECRUITER";
export const FILTER_CLOSED_LAUNCH_DATE = "FILTER_CLOSED_LAUNCH_DATE";
export const FILTER_CLOSED_START_DATE = "FILTER_CLOSED_START_DATE";
export const FILTER_CLOSED_END_DATE = "FILTER_CLOSED_END_DATE";
export const VIEW_CLOSED_REASON = "VIEW_CLOSED_REASON";
export const UPDATE_LAUNCH_DATE = "UPDATE_LAUNCH_DATE";
export const SET_CLOSED_LAUNCH_DATE = "SET_CLOSED_LAUNCH_DATE";
export const FILTER_CLOSED_BD_MANAGER = "FILTER_CLOSED_BD_MANAGER";

export const getClosed = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().closed.closedResultsPerPage;
    const page = getstate().closed.closedCurrentPage;
    const seachText = getstate().closed.closedSearchText;
    const functions = getstate().closed.closedAppliedFunctionFilter;
    const roles = getstate().closed.closedAppliedRolesFilter;
    const recruiters = getstate().closed.closedAppliedRecruiterFilter;
    const start_date = getDateFilter(
      getstate().closed.closedAppliedStartDateFilter[0]
    );
    const end_date = getDateFilter(
      getstate().closed.closedAppliedEndDateFilter[0]
    );
    const launch_date = getDateFilter(
      getstate().closed.closedAppliedLaunchDateFilter[0]
    );
    const bdManagers = getstate().closed.closedAppliedBdManagerFilter;
    const body = {
      search_term: seachText,
      filter_data: {
        functions: functions,
        roles: roles,
        recruiters: recruiters,
        start_date: start_date,
        end_date: end_date,
        launch_date: launch_date,
        bd_managers: bdManagers,
      },
    };

    axiosPostWithToken(
      `${API.GET_CLOSED}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_CLOSED,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch data.",
        });
      }
    });
  };
};

export const viewClosedReason = (project_id, toggleViewReasonmodal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosGetWithToken(`${API.VIEW_CLOSED_REASON}/${project_id}/`, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_CLOSED_REASON,
            payload: res.data.data,
          });
          toggleViewReasonmodal();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              res.data.data.message ||
              "Something went wrong. Unable to fetch data.",
          });
        }
      }
    );
  };
};

export const UpdateLaunchDate = (
  project_id,
  launchDate,
  toggleUpdatelaunchDatemodal
) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const launch_date = formatDate(launchDate, "YYYY-MM-DD");

    const body = {
      launch_date: launch_date,
    };

    axiosPut(`${API.UPDATE_LAUNCH_DATE}/${project_id}/`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SUCCESS_ALERT,
            payload: res.data.data.message,
          });
          toggleUpdatelaunchDatemodal(true);
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              res.data.data.message ||
              "Something went wrong. Unable to fetch data.",
          });
        }
      }
    );
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: SET_CLOSED_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_CLOSED_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: SEARCH_CLOSED,
      payload: value,
    });
  };
};

export const filterFunctions = (functions) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: FILTER_CLOSED_FUNCTIONS,
      payload: functions,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: FILTER_CLOSED_ROLES,
      payload: roles,
    });
  };
};

export const filterRecruiter = (recruiter) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: FILTER_CLOSED_RECRUITER,
      payload: recruiter,
    });
  };
};

export const filterBdManager = (manager) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: FILTER_CLOSED_BD_MANAGER,
      payload: manager,
    });
  };
};

export const filterStartDate = (start_date) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: FILTER_CLOSED_START_DATE,
      payload: start_date,
    });
  };
};

export const filterEndDate = (end_date) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: FILTER_CLOSED_END_DATE,
      payload: end_date,
    });
  };
};

export const filterLaunchDate = (launch_date) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSED_LOADING,
    });
    dispatch({
      type: FILTER_CLOSED_LAUNCH_DATE,
      payload: launch_date,
    });
  };
};

export const setClosedLaunchDate = (launch_date) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CLOSED_LAUNCH_DATE,
      payload: launch_date,
    });
  };
};
