import { API } from "../constants/api";
import { axiosPostWithToken, axiosGetWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { CHANGE_VIEW_PROJECT_TAB } from "./viewProjectTabAction";
import axios from "axios";

export const GET_WORKFLOW_INTEREST_CHECK = "GET_WORKFLOW_INTEREST_CHECK";
export const WORKFLOW_INTEREST_CHECK_LOADING =
  "WORKFLOW_INTEREST_CHECK_LOADING";
export const SET_WORKFLOW_INTEREST_CHECK_ITEMS_PER_PAGE =
  "SET_WORKFLOW_INTEREST_CHECK_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_WORKFLOW_INTEREST_CHECK_PAGE =
  "CHANGE_CURRENT_WORKFLOW_INTEREST_CHECK_PAGE";
export const SEARCH_WORKFLOW_INTEREST_CHECK = "SEARCH_WORKFLOW_INTEREST_CHECK";
export const FILTER_WORKFLOW_INTEREST_CHECK_ROLES =
  "FILTER_WORKFLOW_INTEREST_CHECK_ROLES";
export const FILTER_WORKFLOW_INTEREST_CHECK_SKILLS =
  "FILTER_WORKFLOW_INTEREST_CHECK_SKILLS";
export const WORKFLOW_INTEREST_CHECK_MIN_REL_EXP =
  "WORKFLOW_INTEREST_CHECK_MIN_REL_EXP";
export const WORKFLOW_INTEREST_CHECK_MAX_REL_EXP =
  "WORKFLOW_INTEREST_CHECK_MAX_REL_EXP";
export const WORKFLOW_INTEREST_CHECK_FILTER_REL_EXP =
  "WORKFLOW_INTEREST_CHECK_FILTER_REL_EXP";
export const WORKFLOW_INTEREST_CHECK_SET_TABLE_SELECTED_ROWS =
  "WORKFLOW_INTEREST_CHECK_SET_TABLE_SELECTED_ROWS";
export const RESET_WORKFLOW_INTEREST_CHECK = "RESET_WORKFLOW_INTEREST_CHECK";
export const FILTER_WORKFLOW_INTEREST_CHECK_STATUS =
  "FILTER_WORKFLOW_INTEREST_CHECK_STATUS";
export const WORKFLOW_INTEREST_CHECK_SET_HASDONE_FILTER =
  "WORKFLOW_INTEREST_CHECK_SET_HASDONE_FILTER";
export const WORKFLOW_INTEREST_CHECK_SET_MATCHING_FILTER =
  "WORKFLOW_INTEREST_CHECK_SET_MATCHING_FILTER";
export const WORKFLOW_INTEREST_CHECK_MIN_TOTAL_EXP =
  "WORKFLOW_INTEREST_CHECK_MIN_TOTAL_EXP";
export const WORKFLOW_INTEREST_CHECK_MAX_TOTAL_EXP =
  "WORKFLOW_INTEREST_CHECK_MAX_TOTAL_EXP";
export const WORKFLOW_INTEREST_CHECK_FILTER_TOTAL_EXP =
  "WORKFLOW_INTEREST_CHECK_FILTER_TOTAL_EXP";
export const WORKFLOW_INTEREST_CHECK_VIEW_SELF_ASSESMENT =
  "WORKFLOW_INTEREST_CHECK_VIEW_SELF_ASSESMENT";
export const FILTER_WORKFLOW_INTEREST_CHECK_AVAILABLE_HOURS =
  "FILTER_WORKFLOW_INTEREST_CHECK_AVAILABLE_HOURS";
export const VIEW_WORKFLOW_CANDIDATE_TIMELINE =
  "VIEW_WORKFLOW_CANDIDATE_TIMELINE";
export const FILTER_WORKFLOW_INTEREST_CHECK_FLEXIBEES_SELECTED =
  "FILTER_WORKFLOW_INTEREST_CHECK_FLEXIBEES_SELECTED";
export const FILTER_WORKFLOW_INTEREST_CHECK_ACTIVE_PROJECTS =
  "FILTER_WORKFLOW_INTEREST_CHECK_ACTIVE_PROJECTS";

export const getCandidateTimeline = (id, toggleModal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    axiosGetWithToken(`${API.GET_CANDIDATE_TIMELINE}/${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          console.log("res.", res.data);
          dispatch({
            type: VIEW_WORKFLOW_CANDIDATE_TIMELINE,
            payload: res.data.data,
          });
          toggleModal();
        } else {
          toggleModal('error');
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || res.data.data.detail || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        toggleModal('error');
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};
export const downloadCandidateTimeline = (id, endDownload) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    var config = {
      headers: { Authorization: token, "Content-Type": "application/pdf" },
      responseType: "blob",
    };

    axios
      .get(`${API.DOWNLOAD_CANDIDATE_TIMELINE}/${id}/`, config)
      .then((response) => {
        endDownload();
        console.log(response.data);
        let url = window.URL.createObjectURL(response.data);
        window.open(url);
      });
  };
};

export const getInterestCheck = (projectId) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize =
      getstate().workflowInterestCheck.workflowInterestCheckResultsPerPage;
    const page =
      getstate().workflowInterestCheck.workflowInterestCheckCurrentPage;
    const seachText =
      getstate().workflowInterestCheck.workflowInterestCheckSearchText;
    const roles =
      getstate().workflowInterestCheck.workflowInterestCheckAppliedRolesFilter;
    const skills =
      getstate().workflowInterestCheck.workflowInterestCheckAppliedSkillsFilter;
    const status =
      getstate().workflowInterestCheck.workflowInterestCheckAppliedStatusFilter;
    const total_available_hours =
      getstate().workflowInterestCheck
        .workflowInterestCheckAppliedAvailableHoursFilter;

    const flexibees_selected =
      getstate().workflowInterestCheck
        .candidatesListApplaiedFilterFlexiSelected;
    const active_projects =
      getstate().workflowInterestCheck
        .candidatesListApplaiedFilterActiveProjects;

    const body = {
      search_term: seachText,
      filter_data: {
        roles: roles,
        skills: skills,
        status: status,
        total_available_hours: total_available_hours,
        flexibees_selected: flexibees_selected,
        active_projects: active_projects,
      },
    };

    axiosPostWithToken(
      `${API.GET_WORKFLOW_INTEREST_CHECK}/${projectId}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_WORKFLOW_INTEREST_CHECK,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to fetch data.",
        });
      }
    });
  };
};

export const resetTableData = () => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_VIEW_PROJECT_TAB,
      payload: 0,
    });
    dispatch({
      type: RESET_WORKFLOW_INTEREST_CHECK,
    });
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_INTEREST_CHECK_LOADING,
    });
    dispatch({
      type: SET_WORKFLOW_INTEREST_CHECK_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_INTEREST_CHECK_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_WORKFLOW_INTEREST_CHECK_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_INTEREST_CHECK_LOADING,
    });
    dispatch({
      type: SEARCH_WORKFLOW_INTEREST_CHECK,
      payload: value,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_INTEREST_CHECK_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_INTEREST_CHECK_ROLES,
      payload: roles,
    });
  };
};

export const filterSkills = (skills) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_INTEREST_CHECK_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_INTEREST_CHECK_SKILLS,
      payload: skills,
    });
  };
};

export const filterAvailableHours = (hours) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_INTEREST_CHECK_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_INTEREST_CHECK_AVAILABLE_HOURS,
      payload: hours,
    });
  };
};

export const filterStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_INTEREST_CHECK_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_INTEREST_CHECK_STATUS,
      payload: status,
    });
  };
};

export const setTableRows = (rows) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_INTEREST_CHECK_SET_TABLE_SELECTED_ROWS,
      payload: rows,
    });
  };
};

export const getSelfAssesment = (project_id, candidate_id, toggleModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(
      `${API.VIEW_SELF_ASSESMENT}/${project_id}/${candidate_id}/`,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: WORKFLOW_INTEREST_CHECK_VIEW_SELF_ASSESMENT,
          payload: res.data.data,
        });
        toggleModal("ViewSelfEvaluation");
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "Something went wrong. Unable to fetch data.",
        });
        console.log(`Error: ${res.data.data.message}`);
      }
    });
  };
};

export const setFlexibeesSelectedFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_INTEREST_CHECK_FLEXIBEES_SELECTED,
      payload: value,
    });
  };
};

export const setActiveProjectsFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_INTEREST_CHECK_ACTIVE_PROJECTS,
      payload: value,
    });
  };
};
