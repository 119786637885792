import {
  GET_CLOSED,
  CLOSED_LOADING,
  SET_CLOSED_ITEMS_PER_PAGE,
  CHANGE_CURRENT_CLOSED_PAGE,
  SEARCH_CLOSED,
  FILTER_CLOSED_FUNCTIONS,
  FILTER_CLOSED_ROLES,
  FILTER_CLOSED_RECRUITER,
  FILTER_CLOSED_START_DATE,
  FILTER_CLOSED_END_DATE,
  FILTER_CLOSED_LAUNCH_DATE,
  VIEW_CLOSED_REASON,
  SET_CLOSED_LAUNCH_DATE,
  FILTER_CLOSED_BD_MANAGER,
} from "../actions/closedAction";

const initialState = {
  closedList: [],
  closedPricingHistory: [],
  closedSearchText: "",
  closedAppliedFunctionFilter: [],
  closedAppliedRolesFilter: [],
  closedAppliedStartDateFilter: [],
  closedAppliedEndDateFilter: [],
  closedAppliedLaunchDateFilter: [],
  closedAppliedRecruiterFilter: [],
  closedAppliedBdManagerFilter: [],
  closedTotalResults: null,
  closedCurrentPage: 1,
  closedResultsPerPage: 10,
  closedTotalPages: null,
  closedPageIds: [],
  closedFunctionFilter: [],
  closedRolesFilter: [],
  closedRecruiterFilter: [],
  closedBdMangerFilter: [],
  closedViewClosedReason: {},
  closedsetLaunchDate: "",
  fetchingData: true,
};

const closedReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLOSED: {
      return {
        ...state,
        closedList: action.payload.result.results,
        closedTotalResults: action.payload.result.total_results,
        closedTotalPages: action.payload.result.total_pages,
        closedPageIds: action.payload.result.page_ids,
        closedFunctionFilter: action.payload.filter_data.functions,
        closedRolesFilter: action.payload.filter_data.roles,
        closedRecruiterFilter: action.payload.filter_data.recruiters,
        closedBdMangerFilter: action.payload.filter_data.bd_managers,
        fetchingData: false,
      };
    }
    case CHANGE_CURRENT_CLOSED_PAGE: {
      return {
        ...state,
        closedCurrentPage: action.payload,
      };
    }
    case SET_CLOSED_ITEMS_PER_PAGE: {
      return {
        ...state,
        closedResultsPerPage: action.payload,
      };
    }
    case SEARCH_CLOSED: {
      return {
        ...state,
        closedSearchText: action.payload,
      };
    }
    case FILTER_CLOSED_FUNCTIONS: {
      return {
        ...state,
        closedAppliedFunctionFilter: action.payload,
      };
    }
    case FILTER_CLOSED_ROLES: {
      return {
        ...state,
        closedAppliedRolesFilter: action.payload,
      };
    }
    case FILTER_CLOSED_RECRUITER: {
      return {
        ...state,
        closedAppliedRecruiterFilter: action.payload,
      };
    }
    case FILTER_CLOSED_BD_MANAGER: {
      return {
        ...state,
        closedAppliedBdManagerFilter: action.payload,
      };
    }
    case FILTER_CLOSED_START_DATE: {
      return {
        ...state,
        closedAppliedStartDateFilter: action.payload,
      };
    }
    case FILTER_CLOSED_END_DATE: {
      return {
        ...state,
        closedAppliedEndDateFilter: action.payload,
      };
    }
    case FILTER_CLOSED_LAUNCH_DATE: {
      return {
        ...state,
        closedAppliedLaunchDateFilter: action.payload,
      };
    }
    case CLOSED_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    case VIEW_CLOSED_REASON: {
      return {
        ...state,
        closedViewClosedReason: action.payload,
      };
    }
    case SET_CLOSED_LAUNCH_DATE: {
      return {
        ...state,
        closedsetLaunchDate: action.payload,
      };
    }
    default:
      return state;
  }
};

export default closedReducer;
