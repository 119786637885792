import { combineReducers } from "redux";
import authReducer from "./authReducer";
import commonReducer from "./commonReducer";
import tabReducer from "./tabReducer";
import viewProjectTabReducer from "./viewProjectTabReducer";
import finalSelectionReducer from "./finalSelectionReducer";
import profileTableReducer from "./profileTableReducer";
import recruitmentReducer from "./recruitmentReducer";
import assignRecruiterReducer from "./assignRecruiterReducer";
import viewProjectReducer from "./viewProjectReducer";
import recruitersReducer from "./recruitersReducer";
import notificationReducer from "./notificationReducer";
import closedReducer from "./closedReducer";
import suspendedReducer from "./suspendedReducer";
import workflowAllCandidateReducer from "./workflowAllCandidateReducer";
import workflowShortlistReducer from "./workflowShortlistReducer";
import candidateListReducer from "./candidateReducer";
import workflowInterestCheckReducer from "./workflowInterestCheckReducer";
import workflowAssignmentReducer from "./workflowAssignmentReducer";
import workflowFunctionalReducer from "./workflowFunctionalReducer";
import workflowFlexifitReducer from "./workflowFlexifitReducer";
import workflowFinalReducer from "./workflowFinalReducer";
import projectDetailsReducer from "./projectDetailsReducer";

const rootReducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  tab: tabReducer,
  final: finalSelectionReducer,
  viewProjectTab: viewProjectTabReducer,
  profileTable: profileTableReducer,
  recruit: recruitmentReducer,
  assign: assignRecruiterReducer,
  viewProject: viewProjectReducer,
  recruiters: recruitersReducer,
  notification: notificationReducer,
  closed: closedReducer,
  suspend: suspendedReducer,
  workflowCandidate: workflowAllCandidateReducer,
  workflowShortlist: workflowShortlistReducer,
  workflowInterestCheck: workflowInterestCheckReducer,

  candidates: candidateListReducer,
  workflowAssignment: workflowAssignmentReducer,
  workflowFunctional: workflowFunctionalReducer,
  workflowFlexifit: workflowFlexifitReducer,
  workflowFinal: workflowFinalReducer,
  projectDetails: projectDetailsReducer,

});

export default rootReducers;
