import {
  GET_PROFILETABLE,
  PROFILETABLE_LOADING,
  SET_PROFILETABLE_ITEMS_PER_PAGE,
  CHANGE_CURRENT_PROFILETABLE_PAGE,
  SEARCH_PROFILETABLE,
  FILTER_PROFILETABLE_FUNCTIONS,
  FILTER_PROFILETABLE_ROLES,
  FILTER_PROFILETABLE_REQUESTDATE,
  FILTER_PROFILETABLE_STATUS,
  PROFILETABLE_UPDATE_PROJECT_ID,
  GET_PROFILETABLE_VIEW_PRICING,
  SET_PROFILETABLE_TYPEOFPAYOUT,
  SET_PROFILETABLE_MIN_SALARY,
  SET_PROFILETABLE_MAX_SALARY,
  SET_PROFILETABLE_PROJECT_DURATION_MENU,
  SET_PROFILETABLE_PROJECT_DURATION,
  SET_PROFILETABLE_COMMENTS,
  GET_PROFILETABLE_VIEW_PRICING_LATEST_PRICING_DATA,
  FILTER_PROFILETABLE_BD,
} from "../actions/profileTableAction";

const initialState = {
  profileTableList: [],
  profileTableSearchText: "",
  profileTableAppliedFunctionFilter: [],
  profileTableAppliedRolesFilter: [],
  profileTableAppliedRequestDateFilter: [],
  profileTableAppliedStatusFilter: [],
  profileTableAppliedBDManagerFilter: [],
  profileTableTotalResults: null,
  profileTableCurrentPage: 1,
  profileTableResultsPerPage: 10,
  profileTableTotalPages: null,
  profileTablePageIds: [],
  profileTableFunctionFilter: [],
  profileTableRolesFilter: [],
  profileTableBDManagerFilter: [],
  profileTableProjectid: "",
  profileTableViewProposedSalary: [],
  profileTableTypeOfPayout: "",
  profileTableMinSalary: "",
  profileTableMaxSalary: "",
  profileTableProjectDurationMenu: "",
  profileTableProjectDuration: "",
  profileTableComments: "",
  profileTableLatestPricingData: {},
  fetchingData: true,
};

const profileTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILETABLE: {
      return {
        ...state,
        profileTableList: action.payload.result.results,
        profileTableTotalResults: action.payload.result.total_results,
        profileTableTotalPages: action.payload.result.total_pages,
        profileTablePageIds: action.payload.result.page_ids,
        profileTableFunctionFilter: action.payload.filter_data.functions,
        profileTableRolesFilter: action.payload.filter_data.roles,
        profileTableBDManagerFilter: action.payload.filter_data.bd_managers,
        fetchingData: false,
      };
    }
    case CHANGE_CURRENT_PROFILETABLE_PAGE: {
      return {
        ...state,
        profileTableCurrentPage: action.payload,
      };
    }
    case SET_PROFILETABLE_ITEMS_PER_PAGE: {
      return {
        ...state,
        profileTableResultsPerPage: action.payload,
      };
    }
    case SEARCH_PROFILETABLE: {
      return {
        ...state,
        profileTableSearchText: action.payload,
      };
    }
    case FILTER_PROFILETABLE_FUNCTIONS: {
      return {
        ...state,
        profileTableAppliedFunctionFilter: action.payload,
      };
    }
    case FILTER_PROFILETABLE_ROLES: {
      return {
        ...state,
        profileTableAppliedRolesFilter: action.payload,
      };
    }
    case FILTER_PROFILETABLE_REQUESTDATE: {
      return {
        ...state,
        profileTableAppliedRequestDateFilter: action.payload,
      };
    }
    case FILTER_PROFILETABLE_STATUS: {
      return {
        ...state,
        profileTableAppliedStatusFilter: action.payload,
      };
    }
    case FILTER_PROFILETABLE_BD: {
      return {
        ...state,
        profileTableAppliedBDManagerFilter: action.payload,
      };
    }
    case PROFILETABLE_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    case PROFILETABLE_UPDATE_PROJECT_ID: {
      return {
        ...state,
        profileTableProjectid: action.payload,
      };
    }
    case GET_PROFILETABLE_VIEW_PRICING: {
      return {
        ...state,
        profileTableViewProposedSalary: action.payload,
      };
    }
    case SET_PROFILETABLE_TYPEOFPAYOUT: {
      return {
        ...state,
        profileTableTypeOfPayout: action.payload,
      };
    }
    case SET_PROFILETABLE_MIN_SALARY: {
      return {
        ...state,
        profileTableMinSalary: action.payload,
      };
    }
    case SET_PROFILETABLE_MAX_SALARY: {
      return {
        ...state,
        profileTableMaxSalary: action.payload,
      };
    }
    case SET_PROFILETABLE_PROJECT_DURATION_MENU: {
      return {
        ...state,
        profileTableProjectDurationMenu: action.payload,
      };
    }
    case SET_PROFILETABLE_PROJECT_DURATION: {
      return {
        ...state,
        profileTableProjectDuration: action.payload,
      };
    }
    case SET_PROFILETABLE_COMMENTS: {
      return {
        ...state,
        profileTableComments: action.payload,
      };
    }
    case GET_PROFILETABLE_VIEW_PRICING_LATEST_PRICING_DATA: {
      return {
        ...state,
        profileTableLatestPricingData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default profileTableReducer;
