import {
  GET_FINAL,
  FINAL_LOADING,
  SET_FINAL_ITEMS_PER_PAGE,
  CHANGE_CURRENT_FINAL_PAGE,
  SEARCH_FINAL,
  FILTER_FINAL_FUNCTIONS,
  FILTER_FINAL_ROLES,
  FILTER_FINAL_RECRUITER,
  FILTER_FINAL_BDMANAGER,
  FILTER_FINAL_DATE,
  SET_FINAL_PRICING_HISTORY,
  FILTER_FINAL_RECRUITMENT_DAYS,
  FILTER_FINAL_NO_OF_POSITIONS,
  FILTER_FINAL_FLEXIBEES_SELECTED,
  FILTER_FINAL_CLIENT_SELECTED,
} from "../actions/finalSelectionAction";

const initialState = {
  finalList: [],
  finalPricingHistory: [],
  finalSearchText: "",
  finalAppliedFunctionFilter: [],
  finalAppliedRolesFilter: [],
  finalAppliedRecruiterFilter: [],
  finalAppliedBDManagerFilter: [],
  finalAppliedRecruitmentDaysFilter: [],
  finalAppliedPositionsToBeFilledFilter: [],
  finalAppliedFlexibeesSelectedFilter: [],
  finalAppliedClientSelectedFilter: [],
  finalAppliedDateFilter: [],
  finalTotalResults: null,
  finalCurrentPage: 1,
  finalResultsPerPage: 10,
  finalTotalPages: null,
  finalPageIds: [],
  finalFunctionFilter: [],
  finalRolesFilter: [],
  finalRecruiterFilter: [],
  finalBDManagerFilter: [],
  finalRecruitmentDaysFilter: [],
  finalPositionsToBeFilledFilter: [],
  finalFlexibeesSelectedFilter: [],
  finalClientSelectedFilter: [],
  fetchingData: true,
};

const finalSelectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINAL: {
      return {
        ...state,
        finalList: action.payload.result.results,
        finalTotalResults: action.payload.result.total_results,
        finalTotalPages: action.payload.result.total_pages,
        finalPageIds: action.payload.result.page_ids,
        finalFunctionFilter: action.payload.filter_data.functions,
        finalRolesFilter: action.payload.filter_data.roles,
        finalRecruiterFilter: action.payload.filter_data.recruiters,
        finalBDManagerFilter: action.payload.filter_data.bd_managers,
        finalRecruitmentDaysFilter: action.payload.filter_data.no_of_days,
        finalPositionsToBeFilledFilter:
          action.payload.filter_data.no_of_positions,
        finalFlexibeesSelectedFilter:
          action.payload.filter_data.flexibees_selected,
        finalClientSelectedFilter: action.payload.filter_data.client_selected,
        fetchingData: false,
      };
    }
    case SET_FINAL_PRICING_HISTORY: {
      return {
        ...state,
        finalPricingHistory: action.payload,
      };
    }
    case CHANGE_CURRENT_FINAL_PAGE: {
      return {
        ...state,
        finalCurrentPage: action.payload,
      };
    }
    case SET_FINAL_ITEMS_PER_PAGE: {
      return {
        ...state,
        finalResultsPerPage: action.payload,
      };
    }
    case SEARCH_FINAL: {
      return {
        ...state,
        finalSearchText: action.payload,
      };
    }
    case FILTER_FINAL_FUNCTIONS: {
      return {
        ...state,
        finalAppliedFunctionFilter: action.payload,
      };
    }
    case FILTER_FINAL_ROLES: {
      return {
        ...state,
        finalAppliedRolesFilter: action.payload,
      };
    }
    case FILTER_FINAL_RECRUITER: {
      return {
        ...state,
        finalAppliedRecruiterFilter: action.payload,
      };
    }
    case FILTER_FINAL_BDMANAGER: {
      return {
        ...state,
        finalAppliedBDManagerFilter: action.payload,
      };
    }
    case FILTER_FINAL_RECRUITMENT_DAYS: {
      return {
        ...state,
        finalAppliedRecruitmentDaysFilter: action.payload,
      };
    }
    case FILTER_FINAL_NO_OF_POSITIONS: {
      return {
        ...state,
        finalAppliedPositionsToBeFilledFilter: action.payload,
      };
    }
    case FILTER_FINAL_FLEXIBEES_SELECTED: {
      return {
        ...state,
        finalAppliedFlexibeesSelectedFilter: action.payload,
      };
    }
    case FILTER_FINAL_CLIENT_SELECTED: {
      return {
        ...state,
        finalAppliedClientSelectedFilter: action.payload,
      };
    }
    case FILTER_FINAL_DATE: {
      return {
        ...state,
        finalAppliedDateFilter: action.payload,
      };
    }
    case FINAL_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    default:
      return state;
  }
};

export default finalSelectionReducer;
