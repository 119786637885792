import { CHANGE_TAB, GET_TAB_VALUES } from "../actions/tabAction";

const initialState = {
  initialTab: 0,
  tabValues: {},
};

const tabReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TAB: {
      return {
        ...state,
        initialTab: action.payload,
      };
    }
    case GET_TAB_VALUES: {
      return {
        ...state,
        tabValues: action.payload,
      };
    }
    default:
      return state;
  }
};

export default tabReducer;
