import { API } from "../constants/api";
import { axiosPostWithToken, axiosGetWithToken } from "../services/apiServices";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";
import { CHANGE_VIEW_PROJECT_TAB } from "./viewProjectTabAction";
import axios from "axios";

export const GET_WORKFLOW_FINAL = "GET_WORKFLOW_FINAL";
export const WORKFLOW_FINAL_LOADING = "WORKFLOW_FINAL_LOADING";
export const SET_WORKFLOW_FINAL_ITEMS_PER_PAGE =
  "SET_WORKFLOW_FINAL_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_WORKFLOW_FINAL_PAGE =
  "CHANGE_CURRENT_WORKFLOW_FINAL_PAGE";
export const SEARCH_WORKFLOW_FINAL = "SEARCH_WORKFLOW_FINAL";
export const FILTER_WORKFLOW_FINAL_ROLES = "FILTER_WORKFLOW_FINAL_ROLES";
export const FILTER_WORKFLOW_FINAL_SKILLS = "FILTER_WORKFLOW_FINAL_SKILLS";
export const WORKFLOW_FINAL_MIN_REL_EXP = "WORKFLOW_FINAL_MIN_REL_EXP";
export const WORKFLOW_FINAL_MAX_REL_EXP = "WORKFLOW_FINAL_MAX_REL_EXP";
export const WORKFLOW_FINAL_FILTER_REL_EXP = "WORKFLOW_FINAL_FILTER_REL_EXP";
export const WORKFLOW_FINAL_SET_TABLE_SELECTED_ROWS =
  "WORKFLOW_FINAL_SET_TABLE_SELECTED_ROWS";
export const RESET_WORKFLOW_FINAL = "RESET_WORKFLOW_FINAL";
export const FILTER_WORKFLOW_FINAL_STATUS = "FILTER_WORKFLOW_FINAL_STATUS";
export const WORKFLOW_FINAL_SET_HASDONE_FILTER =
  "WORKFLOW_FINAL_SET_HASDONE_FILTER";
export const WORKFLOW_FINAL_SET_MATCHING_FILTER =
  "WORKFLOW_FINAL_SET_MATCHING_FILTER";
export const WORKFLOW_FINAL_MIN_TOTAL_EXP = "WORKFLOW_FINAL_MIN_TOTAL_EXP";
export const WORKFLOW_FINAL_MAX_TOTAL_EXP = "WORKFLOW_FINAL_MAX_TOTAL_EXP";
export const WORKFLOW_FINAL_FILTER_TOTAL_EXP =
  "WORKFLOW_FINAL_FILTER_TOTAL_EXP";

export const WORKFLOW_FINAL_VIEW_CLIENT_FEEDBACK =
  "WORKFLOW_FINAL_VIEW_CLIENT_FEEDBACK";
export const WORKFLOW_FINAL_VIEW_FINAL_FEEDBACK =
  "WORKFLOW_FINAL_VIEW_FINAL_FEEDBACK";
export const WORKFLOW_FINAL_VIEW_FLEXIFIT_FEEDBACK =
  "WORKFLOW_FINAL_VIEW_FLEXIFIT_FEEDBACK";
export const WORKFLOW_FINAL_AVAILABLE_HOURS_FILTER =
  "WORKFLOW_FINAL_AVAILABLE_HOURS_FILTER";
export const VIEW_WORKFLOW_CANDIDATE_TIMELINE =
  "VIEW_WORKFLOW_CANDIDATE_TIMELINE";

export const FILTER_WORKFLOW_FINAL_FLEXIBEES_SELECTED =
  "FILTER_WORKFLOW_FINAL_FLEXIBEES_SELECTED";

export const FILTER_WORKFLOW_FINAL_ACTIVE_PROJECTS =
  "FILTER_WORKFLOW_FINAL_ACTIVE_PROJECTS";

export const getCandidateTimeline = (id, toggleModal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    axiosGetWithToken(`${API.GET_CANDIDATE_TIMELINE}/${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          console.log("res.", res.data);
          dispatch({
            type: VIEW_WORKFLOW_CANDIDATE_TIMELINE,
            payload: res.data.data,
          });
          toggleModal();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || res.data.data.detail || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const downloadCandidateTimeline = (id, endDownload) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    var config = {
      headers: { Authorization: token, "Content-Type": "application/pdf" },
      responseType: "blob",
    };

    axios
      .get(`${API.DOWNLOAD_CANDIDATE_TIMELINE}/${id}/`, config)
      .then((response) => {
        endDownload();
        console.log(response.data);
        let url = window.URL.createObjectURL(response.data);
        window.open(url);
      });
  };
};

export const getFinal = (projectId, closeModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().workflowFinal.workflowFinalResultsPerPage;
    const page = getstate().workflowFinal.workflowFinalCurrentPage;
    const seachText = getstate().workflowFinal.workflowFinalSearchText;
    const roles = getstate().workflowFinal.workflowFinalAppliedRolesFilter;
    const skills = getstate().workflowFinal.workflowFinalAppliedSkillsFilter;
    const status = getstate().workflowFinal.workflowFinalAppliedStatusFilter;
    const minRelExp =
      getstate().workflowFinal.workflowFinalAppliedMinRelExpFilter;
    const maxRelExp =
      getstate().workflowFinal.workflowFinalAppliedMaxRelExpFilter;
    const minTotalExp =
      getstate().workflowFinal.workflowFinalAppliedMinTotalExpFilter;
    const maxTotalExp =
      getstate().workflowFinal.workflowFinalAppliedMaxTotalExpFilter;
    const matching =
      getstate().workflowFinal.workflowFinalAppliedMatchingFilter === "0"
        ? false
        : true;
    const total_available_hours =
      getstate().workflowFinal.workflowFinalAppliedAvailableHoursFilter;

    const flexibees_selected =
      getstate().workflowFinal.candidatesListApplaiedFilterFlexiSelected;
    const active_projects =
      getstate().workflowFinal.candidatesListApplaiedFilterActiveProjects;

    const body = {
      search_term: seachText,
      filter_data: {
        roles: roles,
        skills: skills,
        status: status,
        final_selection: matching,
        flexibees_selected: flexibees_selected,
        active_projects: active_projects,
        relevant_experience: {
          min: minRelExp,
          max: maxRelExp,
        },
        years_of_experience: {
          min: minTotalExp,
          max: maxTotalExp,
        },
        total_available_hours: total_available_hours,
      },
    };

    axiosPostWithToken(
      `${API.GET_WORKFLOW_FINAL}/${projectId}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_WORKFLOW_FINAL,
          payload: res.data.data,
        });
        if (closeModal) {
          closeModal();
        }
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to fetch data.",
        });
      }
    });
  };
};

export const resetTableData = () => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_VIEW_PROJECT_TAB,
      payload: 0,
    });
    dispatch({
      type: RESET_WORKFLOW_FINAL,
    });
  };
};

export const sendFinalNotification = (body, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(`${API.SEND_FINAL_NOTIFICATION}`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          refresh();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (res.data && res.data.data && res.data.data.message) ||
              "Something went wrong. Unable to process request.",
          });
        }
      }
    );
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: SET_WORKFLOW_FINAL_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_WORKFLOW_FINAL_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: SEARCH_WORKFLOW_FINAL,
      payload: value,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_FINAL_ROLES,
      payload: roles,
    });
  };
};

export const filterSkills = (skills) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_FINAL_SKILLS,
      payload: skills,
    });
  };
};

export const filterAvailableHours = (hours) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FINAL_AVAILABLE_HOURS_FILTER,
      payload: hours,
    });
  };
};

export const filterStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_FINAL_STATUS,
      payload: status,
    });
  };
};

export const changeMinRelExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_MIN_REL_EXP,
      payload: value,
    });
  };
};

export const changeMaxRelExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_MAX_REL_EXP,
      payload: value,
    });
  };
};

export const clearRelExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FINAL_MIN_REL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_FINAL_MAX_REL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_FINAL_FILTER_REL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterRelExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FINAL_FILTER_REL_EXP,
      payload: true,
    });
    filterData();
  };
};

export const changeMinTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_MIN_TOTAL_EXP,
      payload: value,
    });
  };
};

export const changeMaxTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_MAX_TOTAL_EXP,
      payload: value,
    });
  };
};

export const clearTotalExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FINAL_MIN_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_FINAL_MAX_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_FINAL_FILTER_TOTAL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterTotalExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FINAL_FILTER_TOTAL_EXP,
      payload: true,
    });
    filterData();
  };
};

export const setTableRows = (rows) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_SET_TABLE_SELECTED_ROWS,
      payload: rows,
    });
  };
};

export const setHasDoneFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_SET_HASDONE_FILTER,
      payload: data,
    });
  };
};

export const setMatchingFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_FINAL_LOADING,
    });
    dispatch({
      type: WORKFLOW_FINAL_SET_MATCHING_FILTER,
      payload: data,
    });
  };
};

export const getClientFeedback = (final_selection_id, toggleModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(
      `${API.VIEW_CLIENT_FEEDBACK}/${final_selection_id}/`,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: WORKFLOW_FINAL_VIEW_CLIENT_FEEDBACK,
          payload: res.data.data,
        });
        toggleModal("ViewClientFeedback");
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "No details found.",
        });
        console.log(`Error: ${res.data.data.message}`);
      }
    });
  };
};

export const getFinalFeedback = (id, toggleModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.VIEW_FINAL_FEEDBACK}/${id}/`, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: WORKFLOW_FINAL_VIEW_FINAL_FEEDBACK,
            payload: res.data.data,
          });
          toggleModal("ViewFinalFeedback");
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: "Something went wrong. Unable to fetch data.",
          });
          console.log(`Error: ${res.data.data.message}`);
        }
      }
    );
  };
};

export const sendToFinal = (id, toggleModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.SEND_TO_FINAL_SELECTION}/${id}/`, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          toggleModal();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: "Something went wrong. Unable to process request.",
          });
          console.log(`Error: ${res.data.data.message}`);
        }
      }
    );
  };
};

export const updateFinalFeedback = (body, id, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(`${API.UPDATE_FINAL_FEEDBACK}/${id}/`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          refresh();
          dispatch({
            type: SUCCESS_ALERT,
            payload: "Updated Successfully",
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (res.data && res.data.data && res.data.data.message) ||
              "Something went wrong. Unable to process request.",
          });
        }
      }
    );
  };
};

export const moveToNextLevel = (body, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(`${API.MOVE_TO_NEXT_LEVEL}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        refresh();
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Candidate moved to Final Selection Successfully",
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to process request.",
        });
      }
    });
  };
};

export const scheduleFunctionalInterview = (
  assignment_id,
  data,
  successDialog
) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(
      `${API.SCHEDULE_FUNCTIONAL_INTERVIEW}/${assignment_id}/`,
      data,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        successDialog();
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to process request.",
        });
      }
    });
  };
};

export const getFlexifitFeedback = (id, toggleModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.VIEW_FLEXIFIT_FEEDBACK}/${id}/`, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: WORKFLOW_FINAL_VIEW_FLEXIFIT_FEEDBACK,
            payload: res.data.data,
          });
          toggleModal("ViewFlexifitFeedback");
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: "No details found.",
          });
          console.log(`Error: ${res.data.data.message}`);
        }
      }
    );
  };
};

export const setFlexibeesSelectedFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_FINAL_FLEXIBEES_SELECTED,
      payload: value,
    });
  };
};

export const setActiveProjectsFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_FINAL_ACTIVE_PROJECTS,
      payload: value,
    });
  };
};
