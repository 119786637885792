import { API } from "../constants/api";
import { axiosGetWithToken } from "../services/apiServices";

export const CHANGE_TAB = "CHANGE_TAB";
export const GET_TAB_VALUES = "GET_TAB_VALUES";

export const changeTab = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_TAB,
      payload: id,
    });
  };
};

export const getTabValues = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.TAB_COUNT}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_TAB_VALUES,
          payload: res.data.data,
        });
      } else {
        console.log(`Error: ${res.data.data.message}`);
      }
    });
  };
};
