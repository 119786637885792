import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

const Login = lazy(() => import("../container/profile/authentication/Login"));

const NotFound = () => {
  return <Redirect to="/" />;
};

const AuthRoutes = () => {
  return (
    <Switch>
      <Suspense fallback={<div>Loading ...</div>}>
        <Route exact path="/" component={Login} />
        <Route exact path="*" component={NotFound} />
      </Suspense>
    </Switch>
  );
};

export default AuthRoutes;
