import { API } from "../constants/api";
import {
  axiosPostWithToken,
  axiosGetWithToken,
} from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { getDateFilter } from "@flexibees/core";

export const GET_SUSPENDED = "GET_SUSPENDED";
export const SUSPENDED_LOADING = "SUSPENDED_LOADING";
export const SUSPENDED_LOADING_ZOHO = "SUSPENDED_LOADING_ZOHO";
export const SET_SUSPENDED_ITEMS_PER_PAGE = "SET_SUSPENDED_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_SUSPENDED_PAGE = "CHANGE_CURRENT_SUSPENDED_PAGE";
export const SEARCH_SUSPENDED = "SEARCH_SUSPENDED";
export const FILTER_SUSPENDED_FUNCTIONS = "FILTER_SUSPENDED_FUNCTIONS";
export const FILTER_SUSPENDED_ROLES = "FILTER_SUSPENDED_ROLES";
export const PULL_DATA_GET_SUSPENDED = "PULL_DATA_GET_SUSPENDED";
export const FILTER_SUSPENDED_STATUS = "FILTER_SUSPENDED_STATUS";
export const FILTER_SUSPENDED_RECRUITER = "FILTER_SUSPENDED_RECRUITER";
export const FILTER_SUSPENDED_DATE = "FILTER_SUSPENDED_DATE";
export const SET_SUSPENDED_REASON = "SET_SUSPENDED_REASON";
export const FILTER_SUSPENDED_SUSPEND_DATE = "FILTER_SUSPENDED_SUSPEND_DATE";
export const FILTER_SUSPENDED_BD_MANAGER = "FILTER_SUSPENDED_BD_MANAGER";

export const getSuspended = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().suspend.SuspendedResultsPerPage;
    const page = getstate().suspend.SuspendedCurrentPage;
    const seachText = getstate().suspend.SuspendedSearchText;
    const functions = getstate().suspend.SuspendedAppliedFunctionFilter;
    const roles = getstate().suspend.SuspendedAppliedRolesFilter;
    const recruiters = getstate().suspend.SuspendedAppliedRecruiterFilter;
    const date = getDateFilter(
      getstate().suspend.SuspendedAppliedDateFilter[0]
    );
    const suspended_on = getDateFilter(
      getstate().suspend.SuspendedAppliedSuspendedDateFilter[0]
    );
    const bdManagers = getstate().suspend.SuspendedAppliedBdManagersFilter;

    const body = {
      search_term: seachText,
      filter_data: {
        functions: functions,
        roles: roles,
        recruiters: recruiters,
        start_date: date,
        suspended_on: suspended_on,
        bd_managers: bdManagers,
      },
    };

    axiosPostWithToken(
      `${API.GET_SUSPENDED}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_SUSPENDED,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch data.",
        });
      }
    });
  };
};

export const viewSuspendReason = (projectId, showReasonModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.VIEW_SUSPEND_REASON}/${projectId}/`, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SET_SUSPENDED_REASON,
            payload: res.data.data,
          });
          showReasonModal();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      }
    );
  };
};

export const reopenProject = (body, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(`${API.REOPEN_PROJECT}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        refresh();
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to process request",
        });
      }
    });
  };
};
export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: SET_SUSPENDED_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_SUSPENDED_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: SEARCH_SUSPENDED,
      payload: value,
    });
  };
};

export const filterFunctions = (functions) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: FILTER_SUSPENDED_FUNCTIONS,
      payload: functions,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: FILTER_SUSPENDED_ROLES,
      payload: roles,
    });
  };
};

export const filterStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: FILTER_SUSPENDED_STATUS,
      payload: status,
    });
  };
};

export const filterRecruiter = (recruiter) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: FILTER_SUSPENDED_RECRUITER,
      payload: recruiter,
    });
  };
};

export const filterBdManager = (recruiter) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: FILTER_SUSPENDED_BD_MANAGER,
      payload: recruiter,
    });
  };
};

export const filterStartDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: FILTER_SUSPENDED_DATE,
      payload: date,
    });
  };
};

export const filterSuspendedDate = (date) => {
  return async (dispatch) => {
    dispatch({
      type: SUSPENDED_LOADING,
    });
    dispatch({
      type: FILTER_SUSPENDED_SUSPEND_DATE,
      payload: date,
    });
  };
};
