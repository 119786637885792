import { API } from "../constants/api";
import { axiosPostWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";

export const GET_DYNAMIC_DROPDOWN_FROM_CONFIG =
  "GET_DYNAMIC_DROPDOWN_FROM_CONFIG";

export const getDynamicDropdownFromConfig = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {};
    axiosPostWithToken(`${API.GET_DYNAMIC_DROPDOWN_FROM_CONFIG}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_DYNAMIC_DROPDOWN_FROM_CONFIG,
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload: "Something went wrong.",
        });
      });
  };
};
