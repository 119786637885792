import { API } from "../constants/api";
import { axiosPostWithToken, axiosGetWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { CHANGE_VIEW_PROJECT_TAB } from "./viewProjectTabAction";
import axios from "axios";

export const GET_WORKFLOW_SHORTLIST = "GET_WORKFLOW_SHORTLIST";
export const WORKFLOW_SHORTLIST_LOADING = "WORKFLOW_SHORTLIST_LOADING";
export const SET_WORKFLOW_SHORTLIST_ITEMS_PER_PAGE =
  "SET_WORKFLOW_SHORTLIST_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_WORKFLOW_SHORTLIST_PAGE =
  "CHANGE_CURRENT_WORKFLOW_SHORTLIST_PAGE";
export const SEARCH_WORKFLOW_SHORTLIST = "SEARCH_WORKFLOW_SHORTLIST";
export const FILTER_WORKFLOW_SHORTLIST_ROLES =
  "FILTER_WORKFLOW_SHORTLIST_ROLES";
export const FILTER_WORKFLOW_SHORTLIST_SKILLS =
  "FILTER_WORKFLOW_SHORTLIST_SKILLS";
export const WORKFLOW_SHORTLIST_MIN_REL_EXP = "WORKFLOW_SHORTLIST_MIN_REL_EXP";
export const WORKFLOW_SHORTLIST_MAX_REL_EXP = "WORKFLOW_SHORTLIST_MAX_REL_EXP";
export const WORKFLOW_SHORTLIST_FILTER_REL_EXP =
  "WORKFLOW_SHORTLIST_FILTER_REL_EXP";
export const WORKFLOW_SHORTLIST_SET_TABLE_SELECTED_ROWS =
  "WORKFLOW_SHORTLIST_SET_TABLE_SELECTED_ROWS";
export const RESET_WORKFLOW_SHORTLIST = "RESET_WORKFLOW_SHORTLIST";
export const FILTER_WORKFLOW_SHORTLIST_STATUS =
  "FILTER_WORKFLOW_SHORTLIST_STATUS";
export const WORKFLOW_SHORTLIST_SET_HASDONE_FILTER =
  "WORKFLOW_SHORTLIST_SET_HASDONE_FILTER";
export const WORKFLOW_SHORTLIST_SET_MATCHING_FILTER =
  "WORKFLOW_SHORTLIST_SET_MATCHING_FILTER";
export const WORKFLOW_SHORTLIST_MIN_TOTAL_EXP =
  "WORKFLOW_SHORTLIST_MIN_TOTAL_EXP";
export const WORKFLOW_SHORTLIST_MAX_TOTAL_EXP =
  "WORKFLOW_SHORTLIST_MAX_TOTAL_EXP";
export const WORKFLOW_SHORTLIST_FILTER_TOTAL_EXP =
  "WORKFLOW_SHORTLIST_FILTER_TOTAL_EXP";
export const FILTER_WORKFLOW_SHORTLIST_AVAILABLE_HOURS =
  "FILTER_WORKFLOW_SHORTLIST_AVAILABLE_HOURS";
export const VIEW_WORKFLOW_CANDIDATE_TIMELINE =
  "VIEW_WORKFLOW_CANDIDATE_TIMELINE";
export const FILTER_WORKFLOW_SHORTLIST_FLEXIBEES_SELECTED =
  "FILTER_WORKFLOW_SHORTLIST_FLEXIBEES_SELECTED";
export const FILTER_WORKFLOW_SHORTLIST_ACTIVE_PROJECTS =
  "FILTER_WORKFLOW_SHORTLIST_ACTIVE_PROJECTS";

export const getCandidateTimeline = (id, toggleModal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    axiosGetWithToken(`${API.GET_CANDIDATE_TIMELINE}/${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          console.log("res.", res.data);
          dispatch({
            type: VIEW_WORKFLOW_CANDIDATE_TIMELINE,
            payload: res.data.data,
          });
          toggleModal();
        } else {
          toggleModal('error');
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || res.data.data.detail || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        toggleModal('error');
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const downloadCandidateTimeline = (id, endDownload) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    var config = {
      headers: { Authorization: token, "Content-Type": "application/pdf" },
      responseType: "blob",
    };

    axios
      .get(`${API.DOWNLOAD_CANDIDATE_TIMELINE}/${id}/`, config)
      .then((response) => {
        endDownload();
        console.log(response.data);
        let url = window.URL.createObjectURL(response.data);
        window.open(url);
      });
  };
};

export const getShortlist = (projectId) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize =
      getstate().workflowShortlist.workflowShortlistResultsPerPage;
    const page = getstate().workflowShortlist.workflowShortlistCurrentPage;
    const seachText = getstate().workflowShortlist.workflowShortlistSearchText;
    const roles =
      getstate().workflowShortlist.workflowShortlistAppliedRolesFilter;
    const skills =
      getstate().workflowShortlist.workflowShortlistAppliedSkillsFilter;
    const total_available_hours =
      getstate().workflowShortlist.workflowShortlistAppliedAvailableHoursFilter;
    const status =
      getstate().workflowShortlist.workflowShortlistAppliedStatusFilter;
    const minRelExp =
      getstate().workflowShortlist.workflowShortlistAppliedMinRelExpFilter;
    const maxRelExp =
      getstate().workflowShortlist.workflowShortlistAppliedMaxRelExpFilter;
    const minTotalExp =
      getstate().workflowShortlist.workflowShortlistAppliedMinTotalExpFilter;
    const maxTotalExp =
      getstate().workflowShortlist.workflowShortlistAppliedMaxTotalExpFilter;
    const matching =
      getstate().workflowShortlist.workflowShortlistAppliedMatchingFilter ===
      "0"
        ? false
        : true;
    const flexibees_selected =
      getstate().workflowShortlist.candidatesListApplaiedFilterFlexiSelected;
    const active_projects =
      getstate().workflowShortlist.candidatesListApplaiedFilterActiveProjects;

    const body = {
      search_term: seachText,
      filter_data: {
        roles: roles,
        skills: skills,
        status: status,
        matching: matching,
        flexibees_selected: flexibees_selected,
        active_projects: active_projects,
        relevant_experience: {
          min: minRelExp,
          max: maxRelExp,
        },
        years_of_experience: {
          min: minTotalExp,
          max: maxTotalExp,
        },
        total_available_hours: total_available_hours,
      },
    };

    axiosPostWithToken(
      `${API.GET_WORKFLOW_SHORTLIST}/${projectId}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_WORKFLOW_SHORTLIST,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Unable to fetch data.",
        });
      }
    });
  };
};

export const resetTableData = () => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_VIEW_PROJECT_TAB,
      payload: 0,
    });
    dispatch({
      type: RESET_WORKFLOW_SHORTLIST,
    });
  };
};

export const sendShortlistNotification = (body, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPostWithToken(`${API.SEND_SHORTLIST_NOTIFICATION}`, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          refresh();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (res.data && res.data.data && res.data.data.message) ||
              "Something went wrong. Unable to process request.",
          });
        }
      }
    );
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: SET_WORKFLOW_SHORTLIST_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_WORKFLOW_SHORTLIST_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: SEARCH_WORKFLOW_SHORTLIST,
      payload: value,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_SHORTLIST_ROLES,
      payload: roles,
    });
  };
};

export const filterSkills = (skills) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_SHORTLIST_SKILLS,
      payload: skills,
    });
  };
};

export const filterStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_SHORTLIST_STATUS,
      payload: status,
    });
  };
};

export const filterAvailableHours = (hours) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: FILTER_WORKFLOW_SHORTLIST_AVAILABLE_HOURS,
      payload: hours,
    });
  };
};

export const changeMinRelExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_MIN_REL_EXP,
      payload: value,
    });
  };
};

export const changeMaxRelExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_MAX_REL_EXP,
      payload: value,
    });
  };
};

export const clearRelExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_MIN_REL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_MAX_REL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_FILTER_REL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterRelExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_FILTER_REL_EXP,
      payload: true,
    });
    filterData();
  };
};

export const changeMinTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_MIN_TOTAL_EXP,
      payload: value,
    });
  };
};

export const changeMaxTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_MAX_TOTAL_EXP,
      payload: value,
    });
  };
};

export const clearTotalExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_MIN_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_MAX_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_FILTER_TOTAL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterTotalExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_FILTER_TOTAL_EXP,
      payload: true,
    });
    filterData();
  };
};

export const setTableRows = (rows) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_SET_TABLE_SELECTED_ROWS,
      payload: rows,
    });
  };
};

export const setHasDoneFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_SET_HASDONE_FILTER,
      payload: data,
    });
  };
};

export const setMatchingFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: WORKFLOW_SHORTLIST_LOADING,
    });
    dispatch({
      type: WORKFLOW_SHORTLIST_SET_MATCHING_FILTER,
      payload: data,
    });
  };
};

export const setFlexibeesSelectedFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_SHORTLIST_FLEXIBEES_SELECTED,
      payload: value,
    });
  };
};

export const setActiveProjectsFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_WORKFLOW_SHORTLIST_ACTIVE_PROJECTS,
      payload: value,
    });
  };
};
