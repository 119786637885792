export const CHANGE_VIEW_PROJECT_TAB = "CHANGE_VIEW_PROJECT_TAB";

export const changeviewProjectTab = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_VIEW_PROJECT_TAB,
      payload: id,
    });
  };
};
