import {
  GET_SUSPENDED,
  SUSPENDED_LOADING,
  SET_SUSPENDED_ITEMS_PER_PAGE,
  CHANGE_CURRENT_SUSPENDED_PAGE,
  SEARCH_SUSPENDED,
  FILTER_SUSPENDED_FUNCTIONS,
  FILTER_SUSPENDED_ROLES,
  FILTER_SUSPENDED_STATUS,
  FILTER_SUSPENDED_RECRUITER,
  FILTER_SUSPENDED_DATE,
  SET_SUSPENDED_REASON,
  FILTER_SUSPENDED_SUSPEND_DATE,
  FILTER_SUSPENDED_BD_MANAGER,
} from "../actions/suspendedAction";

const initialState = {
  SuspendedList: [],
  SuspendedReason: {},
  SuspendedSearchText: "",
  SuspendedAppliedFunctionFilter: [],
  SuspendedAppliedRolesFilter: [],
  SuspendedAppliedStatusFilter: [],
  SuspendedAppliedRecruiterFilter: [],
  SuspendedAppliedBdManagersFilter: [],
  SuspendedAppliedDateFilter: [],
  SuspendedAppliedSuspendedDateFilter: [],
  SuspendedTotalResults: null,
  SuspendedCurrentPage: 1,
  SuspendedResultsPerPage: 10,
  SuspendedTotalPages: null,
  SuspendedPageIds: [],
  SuspendedFunctionFilter: [],
  SuspendedRolesFilter: [],
  SuspendedRecruiterFilter: [],
  SuspendedBdManagersFilter: [],
  fetchingData: true,
};

const suspendedReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUSPENDED: {
      return {
        ...state,
        SuspendedList: action.payload.result.results,
        SuspendedTotalResults: action.payload.result.total_results,
        SuspendedTotalPages: action.payload.result.total_pages,
        SuspendedPageIds: action.payload.result.page_ids,
        SuspendedFunctionFilter: action.payload.filter_data.functions,
        SuspendedRolesFilter: action.payload.filter_data.roles,
        SuspendedRecruiterFilter: action.payload.filter_data.recruiters,
        SuspendedBdManagersFilter: action.payload.filter_data.bd_managers,
        fetchingData: false,
      };
    }
    case SET_SUSPENDED_REASON: {
      return {
        ...state,
        SuspendedReason: action.payload,
      };
    }
    case CHANGE_CURRENT_SUSPENDED_PAGE: {
      return {
        ...state,
        SuspendedCurrentPage: action.payload,
      };
    }
    case SET_SUSPENDED_ITEMS_PER_PAGE: {
      return {
        ...state,
        SuspendedResultsPerPage: action.payload,
      };
    }
    case SEARCH_SUSPENDED: {
      return {
        ...state,
        SuspendedSearchText: action.payload,
      };
    }
    case FILTER_SUSPENDED_FUNCTIONS: {
      return {
        ...state,
        SuspendedAppliedFunctionFilter: action.payload,
      };
    }
    case FILTER_SUSPENDED_ROLES: {
      return {
        ...state,
        SuspendedAppliedRolesFilter: action.payload,
      };
    }
    case FILTER_SUSPENDED_STATUS: {
      return {
        ...state,
        SuspendedAppliedStatusFilter: action.payload,
      };
    }
    case FILTER_SUSPENDED_RECRUITER: {
      return {
        ...state,
        SuspendedAppliedRecruiterFilter: action.payload,
      };
    }
    case FILTER_SUSPENDED_BD_MANAGER: {
      return {
        ...state,
        SuspendedAppliedBdManagersFilter: action.payload,
      };
    }
    case FILTER_SUSPENDED_DATE: {
      return {
        ...state,
        SuspendedAppliedDateFilter: action.payload,
      };
    }
    case FILTER_SUSPENDED_SUSPEND_DATE: {
      return {
        ...state,
        SuspendedAppliedSuspendedDateFilter: action.payload,
      };
    }
    case SUSPENDED_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    default:
      return state;
  }
};

export default suspendedReducer;
