import {
  GET_WORKFLOW_FUNCTIONAL,
  WORKFLOW_FUNCTIONAL_LOADING,
  SET_WORKFLOW_FUNCTIONAL_ITEMS_PER_PAGE,
  CHANGE_CURRENT_WORKFLOW_FUNCTIONAL_PAGE,
  SEARCH_WORKFLOW_FUNCTIONAL,
  FILTER_WORKFLOW_FUNCTIONAL_ROLES,
  FILTER_WORKFLOW_FUNCTIONAL_SKILLS,
  WORKFLOW_FUNCTIONAL_MIN_REL_EXP,
  WORKFLOW_FUNCTIONAL_MAX_REL_EXP,
  WORKFLOW_FUNCTIONAL_FILTER_REL_EXP,
  WORKFLOW_FUNCTIONAL_MIN_TOTAL_EXP,
  WORKFLOW_FUNCTIONAL_MAX_TOTAL_EXP,
  WORKFLOW_FUNCTIONAL_FILTER_TOTAL_EXP,
  WORKFLOW_FUNCTIONAL_SET_TABLE_SELECTED_ROWS,
  RESET_WORKFLOW_FUNCTIONAL,
  FILTER_WORKFLOW_FUNCTIONAL_STATUS,
  WORKFLOW_FUNCTIONAL_SET_HASDONE_FILTER,
  WORKFLOW_FUNCTIONAL_SET_MATCHING_FILTER,
  GET_FUNCTIONAL_FEEDBACK,
  WORKFLOW_FUNCTIONAL_VIEW_FLEXIFIT_FEEDBACK,
  FILTER_WORKFLOW_FUNCTIONAL_AVAILABLE_HOURS,
  VIEW_WORKFLOW_CANDIDATE_TIMELINE,
  FILTER_WORKFLOW_FUNCTIONAL_ACTIVE_PROJECTS,
  FILTER_WORKFLOW_FUNCTIONAL_FLEXIBEES_SELECTED,
} from "../actions/workflowFunctionalAction";

const initialState = {
  workflowFunctionalList: [],
  workflowFunctionalPricingHistory: [],
  workflowFunctionalSearchText: "",
  workflowFunctionalAppliedRolesFilter: [],
  workflowFunctionalAppliedSkillsFilter: [],
  workflowFunctionalAppliedAvailableHoursFilter: [],
  workflowFunctionalAppliedStatusFilter: [],
  workflowFunctionalTotalResults: null,
  workflowFunctionalCurrentPage: 1,
  workflowFunctionalResultsPerPage: 10,
  workflowFunctionalTotalPages: null,
  workflowFunctionalPageIds: [],
  workflowFunctionalRolesFilter: [],
  workflowFunctionalSkillsFilter: [],
  workflowFunctionalAvailableHoursFilter: [],
  fetchingData: true,
  workflowFunctionalAppliedRelExpFilterState: false,

  workflowFunctionalAppliedMinRelExpFilter: null,
  workflowFunctionalAppliedMaxRelExpFilter: null,
  workflowFunctionalAppliedMinTotalExpFilter: null,
  workflowFunctionalAppliedMaxTotalExpFilter: null,

  workflowFunctionalTableSelectedRows: [],
  workflowFunctionalAppliedMatchingFilter: "0",
  workflowFunctionalAppliedHasDoneFilter: [],
  workflowFunctionalFeedback: {},
  workflowFunctionalFlexifitInterviewDetails: {},

  candidatesListFilterFlexiSelected: [],
  candidatesListFilterActiveProjects: [],
  candidatesListApplaiedFilterFlexiSelected: [],
  candidatesListApplaiedFilterActiveProjects: [],
};

const workflowFunctionalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFLOW_FUNCTIONAL: {
      return {
        ...state,
        workflowFunctionalList: action.payload.result.results,
        workflowFunctionalTotalResults: action.payload.result.total_results,
        workflowFunctionalTotalPages: action.payload.result.total_pages,
        workflowFunctionalPageIds: action.payload.result.page_ids,
        workflowFunctionalRolesFilter: action.payload.filter_data.roles,
        workflowFunctionalSkillsFilter: action.payload.filter_data.skills,
        workflowFunctionalAvailableHoursFilter:
          action.payload.filter_data.total_available_hours,
        candidatesListFilterFlexiSelected:
          action.payload.filter_data.flexibees_selected || [],
        candidatesListFilterActiveProjects:
          action.payload.filter_data.active_projects || [],
        fetchingData: false,
      };
    }
    case CHANGE_CURRENT_WORKFLOW_FUNCTIONAL_PAGE: {
      return {
        ...state,
        workflowFunctionalCurrentPage: action.payload,
      };
    }
    case SET_WORKFLOW_FUNCTIONAL_ITEMS_PER_PAGE: {
      return {
        ...state,
        workflowFunctionalResultsPerPage: action.payload,
      };
    }
    case VIEW_WORKFLOW_CANDIDATE_TIMELINE: {
      return {
        ...state,
        candidateTimeline: action.payload,
      };
    }
    case SEARCH_WORKFLOW_FUNCTIONAL: {
      return {
        ...state,
        workflowFunctionalSearchText: action.payload,
      };
    }

    case FILTER_WORKFLOW_FUNCTIONAL_ROLES: {
      return {
        ...state,
        workflowFunctionalAppliedRolesFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_FUNCTIONAL_SKILLS: {
      return {
        ...state,
        workflowFunctionalAppliedSkillsFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_FUNCTIONAL_AVAILABLE_HOURS: {
      return {
        ...state,
        workflowFunctionalAppliedAvailableHoursFilter: action.payload,
      };
    }

    case FILTER_WORKFLOW_FUNCTIONAL_STATUS: {
      return {
        ...state,
        workflowFunctionalAppliedStatusFilter: action.payload,
      };
    }

    case WORKFLOW_FUNCTIONAL_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }

    case WORKFLOW_FUNCTIONAL_MIN_REL_EXP: {
      return {
        ...state,
        workflowFunctionalAppliedMinRelExpFilter: action.payload,
      };
    }

    case WORKFLOW_FUNCTIONAL_MAX_REL_EXP: {
      return {
        ...state,
        workflowFunctionalAppliedMaxRelExpFilter: action.payload,
      };
    }
    case WORKFLOW_FUNCTIONAL_FILTER_REL_EXP: {
      return {
        ...state,
        workflowFunctionalAppliedRelExpFilterState: action.payload,
      };
    }
    case WORKFLOW_FUNCTIONAL_MIN_TOTAL_EXP: {
      return {
        ...state,
        workflowFunctionalAppliedMinTotalExpFilter: action.payload,
      };
    }

    case WORKFLOW_FUNCTIONAL_MAX_TOTAL_EXP: {
      return {
        ...state,
        workflowFunctionalAppliedMaxTotalExpFilter: action.payload,
      };
    }
    case WORKFLOW_FUNCTIONAL_FILTER_TOTAL_EXP: {
      return {
        ...state,
        workflowFunctionalAppliedTotalExpFilterState: action.payload,
      };
    }
    case WORKFLOW_FUNCTIONAL_SET_TABLE_SELECTED_ROWS: {
      return {
        ...state,
        workflowFunctionalTableSelectedRows: action.payload,
      };
    }
    case RESET_WORKFLOW_FUNCTIONAL: {
      return {
        ...state,
        fetchingData: true,
        workflowFunctionalList: [],
        workflowFunctionalCurrentPage: 1,
        workflowFunctionalResultsPerPage: 10,
        workflowFunctionalTotalPages: null,
        workflowFunctionalSearchText: "",
        workflowFunctionalTableSelectedRows: [],
        workflowFunctionalAppliedRolesFilter: [],
        workflowFunctionalAppliedSkillsFilter: [],
        workflowFunctionalAppliedStatusFilter: [],
        workflowFunctionalAppliedAvailableHoursFilter: [],
        workflowFunctionalAppliedRelExpFilterState: false,
        workflowFunctionalAppliedMinRelExpFilter: null,
        workflowFunctionalAppliedMaxRelExpFilter: null,
        workflowFunctionalAppliedMinTotalExpFilter: null,
        workflowFunctionalAppliedMaxTotalExpFilter: null,
        workflowFunctionalAppliedMatchingFilter: "0",
        workflowFunctionalAppliedHasDoneFilter: [],
        candidatesListApplaiedFilterFlexiSelected: [],
        candidatesListApplaiedFilterActiveProjects: [],
      };
    }
    case WORKFLOW_FUNCTIONAL_SET_HASDONE_FILTER: {
      return {
        ...state,
        workflowFunctionalAppliedHasDoneFilter: action.payload,
      };
    }
    case WORKFLOW_FUNCTIONAL_SET_MATCHING_FILTER: {
      return {
        ...state,
        workflowFunctionalAppliedMatchingFilter: action.payload,
      };
    }
    case GET_FUNCTIONAL_FEEDBACK: {
      return {
        ...state,
        workflowFunctionalFeedback: action.payload,
      };
    }
    case WORKFLOW_FUNCTIONAL_VIEW_FLEXIFIT_FEEDBACK: {
      return {
        ...state,
        workflowFunctionalFlexifitInterviewDetails: action.payload,
      };
    }
    case FILTER_WORKFLOW_FUNCTIONAL_FLEXIBEES_SELECTED: {
      return {
        ...state,
        candidatesListApplaiedFilterFlexiSelected: action.payload,
      };
    }

    case FILTER_WORKFLOW_FUNCTIONAL_ACTIVE_PROJECTS: {
      return {
        ...state,
        candidatesListApplaiedFilterActiveProjects: action.payload,
      };
    }
    default:
      return state;
  }
};

export default workflowFunctionalReducer;
