import { API } from "../constants/api";
import { getDateFilter } from "@flexibees/core";
import { axiosPostWithToken, axiosGetWithToken } from "../services/apiServices";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";

export const GET_PROFILETABLE = "GET_PROFILETABLE";
export const PROFILETABLE_LOADING = "PROFILETABLE_LOADING";
export const SET_PROFILETABLE_ITEMS_PER_PAGE =
  "SET_PROFILETABLE_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_PROFILETABLE_PAGE =
  "CHANGE_CURRENT_PROFILETABLE_PAGE";
export const SEARCH_PROFILETABLE = "SEARCH_PROFILETABLE";
export const FILTER_PROFILETABLE_FUNCTIONS = "FILTER_PROFILETABLE_FUNCTIONS";
export const FILTER_PROFILETABLE_ROLES = "FILTER_PROFILETABLE_ROLES";
export const FILTER_PROFILETABLE_REQUESTDATE =
  "FILTER_PROFILETABLE_REQUESTDATE";
export const FILTER_PROFILETABLE_STATUS = "FILTER_PROFILETABLE_STATUS";
export const PROFILETABLE_UPDATE_PROJECT_ID = "PROFILETABLE_UPDATE_PROJECT_ID";
export const GET_PROFILETABLE_VIEW_PRICING = "GET_PROFILETABLE_VIEW_PRICING";
export const SET_PROFILETABLE_TYPEOFPAYOUT = "SET_PROFILETABLE_TYPEOFPAYOUT";
export const SET_PROFILETABLE_MIN_SALARY = "SET_PROFILETABLE_MIN_SALARY";
export const SET_PROFILETABLE_MAX_SALARY = "SET_PROFILETABLE_MAX_SALARY";
export const SET_PROFILETABLE_PROJECT_DURATION_MENU =
  "SET_PROFILETABLE_PROJECT_DURATION_MENU";
export const SET_PROFILETABLE_PROJECT_DURATION =
  "SET_PROFILETABLE_PROJECT_DURATION";
export const SET_PROFILETABLE_COMMENTS = "SET_PROFILETABLE_COMMENTS";
export const GET_PROFILETABLE_VIEW_PRICING_LATEST_PRICING_DATA =
  "GET_PROFILETABLE_VIEW_PRICING_LATEST_PRICING_DATA";
export const FILTER_PROFILETABLE_BD = "FILTER_PROFILETABLE_BD";

export const profileTable = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().profileTable.profileTableResultsPerPage;
    const page = getstate().profileTable.profileTableCurrentPage;
    const seachText = getstate().profileTable.profileTableSearchText;
    const functions = getstate().profileTable.profileTableAppliedFunctionFilter;
    const roles = getstate().profileTable.profileTableAppliedRolesFilter;
    const requestDate = getDateFilter(
      getstate().profileTable.profileTableAppliedRequestDateFilter[0]
    );
    const status = getstate().profileTable.profileTableAppliedStatusFilter;
    const bd_managers = getstate().profileTable
      .profileTableAppliedBDManagerFilter;

    const body = {
      search_term: seachText,
      filter_data: {
        functions: functions,
        roles: roles,
        request_date: requestDate,
        status: status,
        bd_managers: bd_managers,
      },
    };

    axiosPostWithToken(
      `${API.GET_PROFILETABLE}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_PROFILETABLE,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message || "Something went wrong. Please try again!!",
        });
      }
    });
  };
};

export const viewPricing = (project_id, toggleModal, whichModal) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosGetWithToken(
      `${API.GET_PROFILETABLE_VIEW_PRICING}/${project_id}/`,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_PROFILETABLE_VIEW_PRICING,
          payload: res.data.data.pricing,
        });
        dispatch({
          type: GET_PROFILETABLE_VIEW_PRICING_LATEST_PRICING_DATA,
          payload: res.data.data.latest_pricing_data,
        });
        toggleModal(whichModal);
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (res && res.data && res.data.data && res.data.data.message) ||
            "Something went wrong. Please try again!!",
        });
      }
    });
  };
};

export const updatePricingToBD = (project_id, stageID, refreshTable) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const typeOfPayout = getstate().profileTable.profileTableTypeOfPayout;
    const minSalary = getstate().profileTable.profileTableMinSalary;
    const maxSalary = getstate().profileTable.profileTableMaxSalary;
    const projectDurationMenu = getstate().profileTable
      .profileTableProjectDurationMenu;
    const projectDuration = getstate().profileTable.profileTableProjectDuration;
    const comments = getstate().profileTable.profileTableComments;

    const body = {
      stage: stageID,
      type_of_payout: typeOfPayout,
      min_salary: Number(minSalary),
      max_salary: Number(maxSalary),
      project_duration_unit: projectDurationMenu,
      comments: comments,
      project_duration: Number(projectDuration),
      project: project_id,
    };


    axiosPostWithToken(API.PROFILETABLE_UPDATE_PRICING, body, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SUCCESS_ALERT,
            payload:
              stageID === 1
                ? "Candidate salary proposed successfully"
                : stageID === 4
                ? "Final Candidate Salary updated successfully and Sent for Recruitment"
                : res.data.data.message,
          });
          refreshTable(stageID);
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      }
    );
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_LOADING,
    });
    dispatch({
      type: SET_PROFILETABLE_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_PROFILETABLE_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_LOADING,
    });
    dispatch({
      type: SEARCH_PROFILETABLE,
      payload: value,
    });
  };
};

export const filterFunctions = (functions) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_LOADING,
    });
    dispatch({
      type: FILTER_PROFILETABLE_FUNCTIONS,
      payload: functions,
    });
  };
};

export const filterRoles = (roles) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_LOADING,
    });
    dispatch({
      type: FILTER_PROFILETABLE_ROLES,
      payload: roles,
    });
  };
};

export const filterRequestDate = (requestDate) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_LOADING,
    });
    dispatch({
      type: FILTER_PROFILETABLE_REQUESTDATE,
      payload: requestDate,
    });
  };
};

export const filterStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_LOADING,
    });
    dispatch({
      type: FILTER_PROFILETABLE_STATUS,
      payload: status,
    });
  };
};

export const filterBd = (status) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_LOADING,
    });
    dispatch({
      type: FILTER_PROFILETABLE_BD,
      payload: status,
    });
  };
};

export const updateProject_id = (id) => {
  return async (dispatch) => {
    dispatch({
      type: PROFILETABLE_UPDATE_PROJECT_ID,
      payload: id,
    });
  };
};

export const settypeOfPayoutValue = (typeOfPayout) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILETABLE_TYPEOFPAYOUT,
      payload: typeOfPayout,
    });
  };
};

export const setMinimumSalaryValue = (minSalary) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILETABLE_MIN_SALARY,
      payload: minSalary,
    });
  };
};

export const setMaximumSalaryValue = (maxSalary) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILETABLE_MAX_SALARY,
      payload: maxSalary,
    });
  };
};

export const setProjectDurationMenuValue = (projectDurationMenu) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILETABLE_PROJECT_DURATION_MENU,
      payload: projectDurationMenu,
    });
  };
};

export const setProjectDurationValue = (projectDuration) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILETABLE_PROJECT_DURATION,
      payload: projectDuration,
    });
  };
};

export const setCommentsValue = (comments) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILETABLE_COMMENTS,
      payload: comments,
    });
  };
};
