import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { Provider, useSelector } from "react-redux";
import {
  FbThemeProvider,
  FbcreateMuiTheme,
  FbNoResponse,
} from "@flexibees/core";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
} from "react-router-dom";
import { default as ThemeConfig } from "./config/theme";
import store from "./store";
import ProtectedRoute from "./components/utilities/protectedRoute";
import AuthRoutes from "./routes/auth";
import AdminRoutes from "./routes/admin";
import { SnackbarProvider } from "notistack";
import { MAX_STACK_COUNT } from "./constants/common";
import SnackAlert from "./components/utilities/SnackAlert";
import { ErrorBoundary } from "react-error-boundary";

const flexibeeTheme = FbcreateMuiTheme(ThemeConfig);

const ProviderConfig = () => {
  const isLoggedIn = useSelector((state) => state.auth.token);
  const [path, setPath] = useState(window.location.pathname);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <FbThemeProvider theme={flexibeeTheme}>
      <Router basename={process.env.PUBLIC_URL}>
        {!isLoggedIn ? (
          <Route path="/" component={AuthRoutes} />
        ) : (
          <ProtectedRoute path="/admin" component={AdminRoutes} />
        )}
        {isLoggedIn &&
          (path === process.env.PUBLIC_URL ||
            path === `${process.env.PUBLIC_URL}/` ||
            path === `${process.env.PUBLIC_URL}/admin`) && (
            <Redirect to="/admin/dashboard" />
          )}
      </Router>
    </FbThemeProvider>
  );
};

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <FbNoResponse errorCode="Error" errorMessage="Something went wrong" />
      )}
    >
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={MAX_STACK_COUNT}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <SnackAlert />
          <ProviderConfig />
        </SnackbarProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default hot(App);
